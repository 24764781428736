import Home from './home';
import Offers from './offers';
import ArticleDetail from './article-detail';
import Committee from './committee';
import CommitteeAll from './committee-all';
import EditProfile from './edit-profile';
import UserProfile from './user-profile';
import EditUser from './edit-user';
import Resources from './resources';
import ResourcesManagement from './resource-management';
import AddUser from './add-user';
import UserList from './user-list';
import StaticPages from './set-static-pages';
import NewDetectionForm from './new-detection-form';
import ArticleEditForm from './edit-detection-form';
import LogsAll from './logs-all';
import AuthorList from './author-list';
import AuthorAdd from './author-add';
import AuthorEdit from './author-edit';
import AuthorArticles from './author-articles';
import FileManagment from './file-managment';
import AddNewArticle from './add-new-article';
import AskCommiteeDecision from './ask-comitte-decision';
import AskOpinion from './ask-opinion';
import GiveDecision from './give-decision';

// situation alanı hangi rollerin bu route a erişebileceğini belirtir.
export const allRoutes = [
  {
    path: '/anasayfa',
    component: <Home />,
    situation: ['all'],
  },
  {
    path: '/madde-teklifleri/tum-maddeler',
    component: <Offers />,
    situation: ['all'],
  },
  {
    path: '/madde-detay/:id',
    component: <ArticleDetail />,
    situation: ['all'],
  },
  {
    path: '/heyetler/heyet-detay/:id',
    component: <Committee />,
    situation: ['all'],
  },
  {
    path: '/heyetler/ilim-heyetleri',
    component: <CommitteeAll />,
    situation: ['all'],
  },
  {
    path: '/profil-duzenle',
    component: <EditProfile />,
    situation: ['all'],
  },
  {
    path: '/kullanici-bilgileri',
    component: <UserProfile />,
    situation: ['all'],
  },
  {
    path: '/uye-islemleri/uye-duzenle/:id',
    component: <EditUser />,
    situation: ['admin'],
  },
  {
    path: '/e-kaynaklar',
    component: <Resources />,
    situation: ['all'],
  },
  {
    path: '/e-kaynak/ekle',
    component: <ResourcesManagement />,
    situation: ['resourcesManager'],
  },
  {
    path: '/e-kaynak/duzenle/:id',
    component: <ResourcesManagement />,
    situation: ['resourcesManager'],
  },
  {
    path: '/uye-islemleri/uye-ekle',
    component: <AddUser />,
    situation: ['admin'],
  },
  {
    path: '/uye-islemleri/uye-listesi',
    component: <UserList />,
    situation: ['admin'],
  },
  {
    path: '/madde-teklifleri/:id/teklif-edilen-maddeler',
    component: <Offers />,
    situation: ['admin'],
  },
  {
    path: '/yeni-icerik-ekle/:id',
    component: <AddNewArticle />,
    situation: ['all'],
  },
  {
    path: '/ilim-heyetinden-oneri-iste/:id',
    component: <AskCommiteeDecision />,
    situation: ['all'],
  },

  {
    path: '/heyet-karar/:step/:offerID/:comitteeID/:status',
    component: <AskOpinion />,
    situation: ['all'],
  },
  {
    path: '/heyet-karar/:step/:offerID/:status',
    component: <GiveDecision />,
    situation: ['all'],
  },
  {
    path: '/madde-teklifleri/:id/heyetten-cikan-maddeler',
    component: <Offers />,
    situation: ['admin'],
  },
  {
    path: '/maddelerim',
    component: <AuthorArticles />,
    situation: ['admin', 'author'],
  },
  {
    path: '/sabit-sayfalar',
    component: <StaticPages />,
    situation: ['admin'],
  },
  {
    path: '/madde-teklifleri/:id/sorumlu-olunan-maddeler',
    component: <Offers />,
    situation: ['admin'],
  },
  {
    path: '/madde-teklifleri/:id/gorus-istenen-maddeler',
    component: <Offers />,
    situation: ['admin'],
  },
  {
    path: '/madde-teklifleri/:id/taslak-maddeler',
    component: <Offers />,
    situation: ['admin'],
  },
  {
    path: '/yeni-madde-teklifi/madde-tespit-formu',
    component: <NewDetectionForm />,
    situation: ['admin', 'canOffer'],
  },
  {
    path: '/madde-teklifleri/:id',
    component: <Offers />,
    situation: ['admin'],
  },
  {
    path: '/madde-tespit-formu/duzenle/:id',
    component: <ArticleEditForm />,
    situation: ['admin', 'executive'],
  },
  {
    path: '/loglar/tum-loglar',
    component: <LogsAll />,
    situation: ['executive'],
  },
  {
    path: '/yazar-islemleri/yazar-listesi',
    component: <AuthorList />,
    situation: ['all'],
  },
  {
    path: '/yazar-islemleri/yazar-ekle',
    component: <AuthorAdd />,
    situation: ['admin', 'executive'],
  },
  {
    path: '/yazar-islemleri/yazar-duzenle/:id',
    component: <AuthorEdit />,
    situation: ['all'],
  },
  {
    path: '/dosya-yonetimi',
    component: <FileManagment />,
    situation: ['admin'],
  },
];
