import { useState } from 'react';

import { EditOutlined, LinkOutlined } from '@ant-design/icons';
import { Descriptions } from 'antd';
import { useUserHasPermission } from 'hooks';
import { FormattedMessage } from 'react-intl';
import { contributorWorks } from 'utils';

import ArticleSupervisorEditModal from './ArticleSupervisorEditModal';

const { Item } = Descriptions;

export const OrderDetailDescriptions = ({ offer }) => {
  const { handlePermission } = useUserHasPermission();
  const [editModal, setEditModal] = useState({
    visible: false,
    committeeID: null,
  });

  const items = [
    {
      label: <FormattedMessage id="article.detail.articleCode" />,
      content: offer.articleCodeFormatted,
    },
    {
      label: <FormattedMessage id="article.detail.ownerCommittee" />,
      content: offer.ownerCommittee?.name,
    },
    {
      label: <FormattedMessage id="article.detail.articleSupervisor" />,
      content: (
        <>
          {offer.articleSupervisor.fullName}{' '}
          {handlePermission(offer.ownerCommittee.id) && (
            <span
              className="gx-text-primary"
              style={{
                cursor: 'pointer',
              }}
            >
              <EditOutlined
                onClick={() =>
                  setEditModal({
                    visible: true,
                    committeeID: offer.ownerCommittee.id,
                    initialData: offer.articleSupervisor['@id'],
                  })
                }
              />
            </span>
          )}
        </>
      ),
    },
    ...[
      offer.committeeContributions?.length > 0 && {
        label: <FormattedMessage id="article.detail.committeeContributionsWorkType" />,
        content: offer.committeeContributions?.map((item) => (
          <div
            key={item.committee.id}
            className="gx-my-2"
          >
            {item?.committee?.name}

            <ul className="gx-m-0">
              {item.type.sort().map((type) =>
                type !== 5 ? (
                  <li key={type}>{contributorWorks[type]}</li>
                ) : (
                  <li key={type}>
                    <span className="gx-font-weight-semi-bold">
                      <FormattedMessage id="article.detail.articleTypeOther" />
                    </span>
                    {item?.other}
                  </li>
                )
              )}
            </ul>
          </div>
        )),
      },
    ],
    {
      label: <FormattedMessage id="article.detail.oldTitle" />,
      content: <FormattedMessage id={`appModule.${offer.oldTitle ? 'yes' : 'no'}`} />,
    },
    {
      label: <FormattedMessage id="article.detail.oldArticlesQuestion" />,
      content: <FormattedMessage id={`appModule.${offer.oldArticles?.length > 0 ? 'yes' : 'no'}`} />,
    },
    ...[
      offer.oldArticles?.length > 0 && {
        label: <FormattedMessage id="article.detail.oldArticles" />,
        content: offer.oldArticles?.map((offer) => (
          <div key={offer.title}>
            <a
              title={offer?.title}
              href={offer.link}
              rel="noopener noreferrer"
              target="_blank"
              className="gx-mr-2"
            >
              <LinkOutlined />
            </a>

            {offer.title}
          </div>
        )),
      },
    ],
    offer.oldTitle && {
      label: <FormattedMessage id="article.detail.oldTitleDescription" />,
      content: (
        <div>
          <a
            title={offer.oldTitle.title}
            href={offer.oldTitle.link}
            rel="noopener noreferrer"
            target="_blank"
            className="gx-mr-2"
          >
            <LinkOutlined />
          </a>

          {offer.oldTitle.title}
        </div>
      ),
    },
    {
      label: <FormattedMessage id="article.detail.detectReason" />,
      content: offer.detectReason,
    },
    {
      label: <FormattedMessage id="article.detail.articleLengthReason" />,
      content: offer.articleLengthReason,
    },
  ];

  return (
    <>
      <Descriptions
        layout="horizontal"
        className="order-descriptions gx-mb-4"
        column={1}
        bordered
      >
        {items.map(
          (item, i) =>
            item && (
              <Item
                key={i}
                label={item.label}
              >
                {item.content}
              </Item>
            )
        )}
      </Descriptions>
      <ArticleSupervisorEditModal
        open={editModal.visible}
        onCancel={() => setEditModal({ visible: false, committeeID: null, initialData: null })}
        initialData={editModal.initialData}
        offerID={offer.id}
        id={editModal.committeeID}
      />
    </>
  );
};
