import { useCallback, useEffect, useState } from 'react';

import { Button, Col, Divider, Row, Tabs } from 'antd';
import { ArrowLeftOutlined, FieldTimeOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { CircularProgress, Title } from 'components';
import { ArticleOfferDetail, ArticleProcess, Timeline } from 'components/ArticleOffer';
import { ArticleOrderDetail } from 'components/ArticleOrder';
import { useGetCurrentUserQuery, useGetOfferQuery } from 'services';
import ProtectedRoute from 'components/ProtectRoute';

const { TabPane } = Tabs;

const ArticleDetail = () => {
  const { id } = useParams();
  const { data: offer, isLoading } = useGetOfferQuery(id);
  const { data: current_user } = useGetCurrentUserQuery();

  const [timelineVisible, setTimelineVisible] = useState(false);
  const [process, setProcess] = useState('1');

  const handleProcess = useCallback(() => {
    const step = Number.parseInt(offer?.step);
    switch (step) {
      case 'undefined':
        setProcess('1');
        break;
      case isNaN(step):
        setProcess('1');
        break;
      case 1:
        setProcess('1');
        break;
      case 3:
        setProcess('2');
        break;
      case 4:
        setProcess('2');
        break;
      default:
        setProcess(step.toString());
        break;
    }
  }, [offer?.step]);

  useEffect(() => {
    handleProcess();
  }, [handleProcess]);

  if (!offer || isLoading) {
    return <CircularProgress />;
  }

  return (
    <ProtectedRoute user={offer.authors.map((author) => author.author.id)}>
      <Title>{`${offer.title + ' - Detay'}`}</Title>
      <Row
        justify="space-between"
        gutter={3}
      >
        <Col>
          <h1 className="gx-h1-lg">{offer?.title}</h1>
        </Col>

        {(current_user.isAdmin || current_user.isOrderUnit) && (
          <Col>
            <Button
              ghost
              style={{ color: '#BF6800' }}
              disabled={!offer?.isView}
              onClick={() => setTimelineVisible(!timelineVisible)}
            >
              {timelineVisible ? (
                <>
                  <FormattedMessage id="goBack" />
                  <ArrowLeftOutlined />
                </>
              ) : (
                <>
                  <FormattedMessage id="article.timeline" />
                  <FieldTimeOutlined />
                </>
              )}
            </Button>
          </Col>
        )}
      </Row>

      <Divider className="gx-mt-0 gx-mb-4" />

      {timelineVisible ? (
        <Timeline id={offer.id} />
      ) : (
        <>
          {current_user.isAdmin && (
            <Row
              justify="center"
              gutter={3}
            >
              <Col span={18}>
                <ArticleProcess
                  offerStep={offer?.step}
                  process={process}
                  setProcess={setProcess}
                />
              </Col>
            </Row>
          )}

          <Tabs
            animated
            centered
            type="card"
            activeKey={process.toString()}
            tabBarStyle={{ display: 'none' }}
            destroyInactiveTabPane
          >
            <TabPane key="1">
              <ArticleOfferDetail
                offer={offer}
                process={process}
              />
            </TabPane>
            <TabPane key="2">
                <ArticleOrderDetail
                  offer={offer}
                  process={process}
                />
            </TabPane>
          </Tabs>
        </>
      )}
    </ProtectedRoute>
  );
};

export default ArticleDetail;
