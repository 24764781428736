import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Button, Col, Modal, Row, Table, Tag } from 'antd';
import { CloudDownloadOutlined, FilterFilled } from '@ant-design/icons';
import { Link, useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import dayjs from 'dayjs';
import scrollIntoView from 'scroll-into-view';
import { useAllOffersVersions, useCommittees } from 'useSWR';
import { useInitialValueMaker, usePushFilteredUrl, useWindowSize } from 'hooks';
import { exportTable, STEP_DEF } from 'utils';
import { CircularProgress, Input, Title } from 'components';
import { FilterModal, Order, TableAlert } from 'components/ArticlesTable';
import { ArticleOrderActions } from 'components/ArticleOrder';
import { setOfferTable, setShouldScroll, setTableOrder } from 'slices/commonSlice';
import { higherCommittee } from 'constant/static';
import DecisionAskedModal from 'components/Modal/DecisionAsked';
import { useGetCurrentUserQuery, useGetFilteredUserListQuery } from 'services';

const filterNames = {
  otherCommittees: 'Teklif Edilen İlim Heyeti',
  owner: 'Formu Dolduran',
  ownerCommittee: 'Sorumlu İlim Heyeti',
  dateRange: 'Tarih Aralığı',
  committeeContributions: 'Katkıda Bulunacak İlim Heyeti',
  status: 'Genel Durum',
};

const { Search } = Input;

const NextPage = ({ currentPageOptions, committeeId, orderType, nextPage }) => {
  const { pageSize } = currentPageOptions;
  const { offers } = useAllOffersVersions({
    committeeId,
    pageSize,
    orderType,
    filtered: nextPage,
  });

  return (
    <span
      className="gx-d-none"
      data-users={offers}
    />
  );
};

const renderAuthors = (author) => {
  return author.map((a, i, { length }) => (
    <div key={a && a.id}>
      {a.author.user?.fullName}
      {length - 1 !== i && ','}
    </div>
  ));
};

const Articles = () => {
  const dispatch = useDispatch();
  const { pathname, search } = useLocation();
  const { formatMessage } = useIntl();
  const { width, height } = useWindowSize();
  const { id } = useParams();
  const { data: current_user } = useGetCurrentUserQuery();
  const [decisionModal, setDecisionModal] = useState({
    visible: false,
    data: null,
  });

  const [searchOwner, setSearchOwner] = useState('');

  const shouldScroll = useSelector(({ common }) => common.offerShouldTableScroll);
  const tableOrder = useSelector(({ common }) => common.tableOrder);
  const scrollID = useSelector(({ common }) => common.offerID);

  const { data: ownerOptions } = useGetFilteredUserListQuery({
    fullName: searchOwner,
  });

  const urlFilter = pathname.split('/');
  // bu iki koşulda veriler versiondan değil ana veriden geldiği için tablodaki dataindex lerin değiştirilmesi için kullanılacak condition
  const isDraft = urlFilter.includes('taslak-maddeler');
  const isAskDecision = urlFilter.includes('gorus-istenen-maddeler');

  const stringId = id ?? urlFilter[2] !== 'tum-maddeler' ? urlFilter[2] : '';

  let subMenus,
    subTitle = '';

  if (urlFilter.includes('teklif-edilen-maddeler')) {
    subMenus = `&otherCommittees.id=${stringId}`;
    subTitle = formatMessage({ id: 'sidebar.offeredArticles' });
  }
  if (urlFilter.includes('sorumlu-olunan-maddeler')) {
    subMenus = `&committee=${stringId}`;
    subTitle = formatMessage({ id: 'sidebar.ownerArticles' });
  }
  if (urlFilter.includes('gorus-istenen-maddeler')) {
    subMenus = `opinionCommittee=${stringId}`;
    subTitle = formatMessage({ id: 'sidebar.askDecisionArticles' });
  }
  if (urlFilter.includes('heyetten-cikan-maddeler')) {
    subMenus = `&extracted=${stringId}`;
    subTitle = formatMessage({ id: 'sidebar.removedFromCommitteeArticles' });
  }
  if (isDraft) {
    subMenus = `&committee=${stringId}&isDraft=true`;
    subTitle = formatMessage({ id: 'sidebar.draftArticles' });
  }

  const { page, pageSize, title, filterModal: filterModals, filterModalURL, filterStatusObjects, filterStatusURL } = useInitialValueMaker(search);

  const filtersRef = useRef();
  const debounce = useRef();

  const [urlFilters, setUrlFilters] = useState({
    page: page || 1,
    pageSize: pageSize || 20,
    title: title ?? '',
    filterModalURL: filterModalURL ?? '',
    filterModalObjects: filterModals ?? {},
    filterStatusURL: filterStatusURL ?? '',
    filterStatusObjects: filterStatusObjects ?? {},
    filterTags: [],
    filteredURL: search.replaceAll('?', ''),
  });

  const { newRoute, nextPage } = usePushFilteredUrl(urlFilters, pathname);

  const [loading, setLoading] = useState(false);
  const [wait, setWait] = useState(false);
  const [order, setOrder] = useState({ type: tableOrder.type, order: tableOrder.order });
  const [isModalVisible, setIsModalVisible] = useState(false);

  const { committees, isLoading: titleLoading } = useCommittees(stringId, id);
  const {
    offers,
    isLoading,
    url: downloadURL,
    mutate,
  } = useAllOffersVersions({
    subMenus,
    committeeId: stringId,
    pageSize: urlFilters.pageSize,
    orderType: order,
    filtered: newRoute ?? urlFilters.filterModalURL + urlFilters.filterStatusURL,
  });
  const searchName = (text) => {
    setLoading(true);
    clearTimeout(debounce.current);
    debounce.current = setTimeout(() => {
      setUrlFilters((prevState) => ({ ...prevState, title: text, page: 1 }));
      setLoading(false);
    }, 300);
  };

  const clearFilters = () => {
    if (filtersRef.current) {
      filtersRef.current.clearFilters();
    } else {
      // Clear filter modal values
      localStorage.removeItem('filterModalValues');
      setUrlFilters((prevState) => ({
        ...prevState,
        page: 1,
        filterModalURL: '',
        filterStatusURL: '',
      }));
    }
  };
  const columns = [
    {
      title: <FormattedMessage id="article.detail.FormNumber" />,
      dataIndex: isDraft || isAskDecision ? 'formNumber' : 'targetOffer',
      width: 90,
      align: 'center',
      render: (a) => <span>{a?.formNumber ?? a}</span>,
      fixed: width > 575 ? 'left' : null,
    },
    {
      title: (
        <Order
          setOrder={setOrder}
          order={order}
          title={<FormattedMessage id="article.detail.articleTitle" />}
          type="title"
        />
      ),
      className: order.type === 'title' && order.order !== null ? 'active-order' : newRoute.includes('title') && 'active-filter-column',
      dataIndex: 'title',
      width: 240,
      render: (article, record) => <Link to={`/madde-detay/${isDraft || isAskDecision ? record.id : record.targetOffer?.id}`}>{article}</Link>,
      fixed: width > 575 ? 'left' : null,
    },
    {
      title: <FormattedMessage id="article.filter.owner" />,
      dataIndex: isDraft || isAskDecision ? 'owner' : 'targetOffer',
      width: 110,
      align: 'center',
      className: newRoute.includes('owner=') && 'active-filter-column',
      render: (a) => <span>{a?.owner?.fullName ?? a?.fullName}</span>,
    },
    {
      title: <FormattedMessage id="article.filter.offeredAuthor" />,
      dataIndex: 'authors',
      width: 110,
      align: 'center',
      className: newRoute.includes('author=') && 'active-filter-column',
      hidden: !(current_user?.isAdmin || current_user?.isOrderUnit),
      render: (author) => (author ? renderAuthors(author) : '-'),
    },
    {
      title: (
        <Order
          title={<FormattedMessage id="article.filter.date" />}
          type="createdAt"
          setOrder={setOrder}
          order={order}
        />
      ),
      dataIndex: 'createdAt',
      align: 'center',
      width: 140,
      className: order.type === 'createdAt' && order.order !== null ? 'active-order' : newRoute.includes('createdAt') && 'active-filter-column',
      render: (updatedAt) => dayjs(updatedAt).format('DD/MM/YYYY - HH:mm'),
    },
    {
      title: <FormattedMessage id="article.detail.ownerCommittee" />,
      dataIndex: 'ownerCommittee',
      align: 'center',
      width: 130,
      className: newRoute.includes('ownerCommittee') && 'active-filter-column',
      render: (ownerCommittee) => <span>{isDraft ? committees?.name : ownerCommittee?.name && ownerCommittee?.name}</span>,
    },
    {
      title: <FormattedMessage id="article.filter.askedCommitteeDecision" />,
      dataIndex: isDraft || isAskDecision ? ['ouCommitteeLatestOpinion', 'decisions'] : ['targetOffer', 'ouCommitteeLatestOpinion', 'decisions'],
      align: 'center',
      width: 130,
      render: (askedCommittee) =>
        askedCommittee?.length > 0 ? (
          <span
            style={{
              cursor: 'pointer',
            }}
            onClick={() => setDecisionModal({ visible: true, data: askedCommittee })}
          >
            {askedCommittee.length} Heyet
          </span>
        ) : (
          '-'
        ),
    },
    {
      title: <FormattedMessage id="article.filter.generalProcess" />,
      dataIndex: ['targetOffer', 'step'],
      align: 'center',
      width: 130,
      render: (step) => (
        <p className="gx-py-2 gx-m-0">
          <FormattedMessage id={step ? `article.${STEP_DEF[step]}.title` : `article.${STEP_DEF[1]}.title`} />
        </p>
      ),
    },
    {
      title: <FormattedMessage id="article.filter.generalSituation" />,
      dataIndex: isDraft || isAskDecision ? 'status' : 'targetOffer',
      className: `${newRoute.includes('status') && 'active-filter-column'} article-table-general-situation`,
      align: 'center',
      width: 130,
      render: (a) => (
        <>
          <TableAlert karar={isDraft || isAskDecision ? a : a && a.status} />
        </>
      ),
    },
    {
      title: <FormattedMessage id="appModule.action" />,
      key: 'action',
      align: 'center',
      width: 50,
      hidden: !(current_user?.isAdmin || current_user?.isOrderUnit),
      render: (_, data) => (
        <ArticleOrderActions
          offer={data}
          mutateArticles={mutate}
        />
      ),
    },
  ].filter((item) => !item.hidden);

  const pagination = {
    defaultCurrent: urlFilters.page,
    current: page,
    defaultPageSize: urlFilters.pageSize || 20,
    total: offers?.['hydra:totalItems'] ?? 1,
    showTotal: (total) =>
      `${formatMessage(
        {
          id: 'table.totalItem',
        },
        { type: 'Madde' }
      )} ${total}`,
    position: ['bottomRight'],
    responsive: true,
    size: 'default',
    onChange: (page, pageSize) => {
      setUrlFilters((prevState) => ({ ...prevState, page, pageSize }));
    },
    pageSizeOptions: [10, 20, 50],
    showSizeChanger: true,
    onShowSizeChange: (current, size) => setUrlFilters((prevState) => ({ ...prevState, page: 1, pageSize: size })),
  };

  /**
   * filtrele kısmında "formu dolduran" input alanında çıkacak isim önerileri, her bir farklı isim
   * dizide sadece bir kere yer alacak şekilde filtreleniyor.
   */

  const handleScroll = useCallback(() => {
    scrollIntoView(document.querySelector('.scroll-row'), {
      align: {
        top: 0,
        left: 0,
      },
    });
  }, []);

  const handleExportTable = async () => {
    setWait(true);
    const fileName = `${committees?.name ?? 'Tüm'} ${subTitle ? '- ' + subTitle : 'Teklifler'}`;
    let url = downloadURL;
    if (isDraft || isAskDecision) {
      url = url.replaceAll('/article_offers?', '/article_offers/export?');
    } else {
      url = url.replaceAll('/article_offer_versions?', '/article_offer_versions/export?');
    }
    await exportTable(url, fileName);
    setWait(false);
  };

  useEffect(() => {
    if (shouldScroll) {
      setTimeout(handleScroll, 500);
      dispatch(setShouldScroll(false));
    }
  }, [handleScroll, shouldScroll, dispatch]);

  useEffect(() => {
    dispatch(setTableOrder(order));
  }, [order, dispatch]);

  if (titleLoading) <CircularProgress />;

  return (
    <Row
      align="center"
      justify="center"
    >
      <Title>{`${committees?.name ?? 'Tüm'} ${subTitle ? '- ' + subTitle : 'Teklifler'}`}</Title>

      <Col span={24}>
        <div className="offers-table-columns">
          <div className="gx-d-flex gx-justify-content-between gx-aling-items-center">
            <h1 className="gx-h1-lg">
              <FormattedMessage
                id="article.table.title"
                values={{
                  committee: stringId ? `- ${committees?.name}` : '',
                  // bu alanda subtitle dolu ise ve id 1 ve 2 değilse 62. satırda bulunan subtitle lar + Madde Teklifleri yazması sağlandı. Bu sayede Taslaklar - Arap Dili Edebiyatı gibi başlıklar yazılabiliyor.
                  subtitle: subTitle !== '' ? subTitle : !higherCommittee.includes(+stringId) ? 'Madde Teklifleri - Tam Liste' : 'Madde Teklifleri ',
                }}
              />
            </h1>

            <Button
              loading={wait}
              disabled={!(offers?.['hydra:member']?.length > 0 || offers?.length > 0)}
              onClick={handleExportTable}
              icon={<CloudDownloadOutlined />}
            >
              <FormattedMessage id="app.export" />
            </Button>
          </div>
          <Row gutter={6}>
            <Col
              xs={18}
              md={11}
            >
              <Search
                name="offerName"
                onSearch={searchName}
                defaultValue={title}
                placeholder={formatMessage({ id: 'article.table.search' })}
                allowClear
                enterButton
              />
            </Col>

            <Col span={6}>
              <Button
                type="primary"
                onClick={() => setIsModalVisible(true)}
                icon={<FilterFilled />}
                className="gx-mr-0"
              >
                <FormattedMessage id="article.filter" />
              </Button>

              {(urlFilters.filterModalURL || urlFilters.filterStatusURL) && (
                <Button
                  type="link"
                  className=""
                  onClick={clearFilters}
                >
                  Temizle
                </Button>
              )}
            </Col>

            <Modal
              title={<FormattedMessage id="article.filter" />}
              open={isModalVisible}
              onOk={() => setIsModalVisible(false)}
              onCancel={() => setIsModalVisible(false)}
              width={900}
              footer={null}
            >
              <FilterModal
                isLoading={isLoading}
                handleCancel={() => setIsModalVisible(false)}
                tableType={subMenus}
                handleSearchOwner={setSearchOwner}
                setUrlFilters={setUrlFilters}
                filterModals={filterModals}
                ownerOptions={ownerOptions}
              />
            </Modal>

            <div
              style={{
                bottom: offers?.['hydra:member']?.length > 0 || offers?.length > 0 ? -25 : -20,
              }}
              className="gx-position-absolute "
            >
              {urlFilters?.filterTags?.map(
                (item) =>
                  item.isTouched && (
                    <Tag
                      key={item.value}
                      onClick={() => setIsModalVisible(true)}
                      style={{ cursor: 'pointer' }}
                    >
                      {' '}
                      {filterNames[item.value]}{' '}
                    </Tag>
                  )
              )}
            </div>
          </Row>
          <Table
            bordered
            columns={columns}
            pagination={pagination}
            dataSource={offers && (offers['hydra:member'] || offers)}
            rowKey={(record) => (isDraft || isAskDecision ? record.id : record.targetOffer?.id)}
            rowClassName={(record) => {
              const recID = isDraft || isAskDecision ? record.id : record.targetOffer?.id;
              return recID === scrollID && 'scroll-row';
            }}
            onRow={(record) => ({
              onClick: () =>
                dispatch(
                  setOfferTable({
                    offerID: isDraft || isAskDecision ? record.id : record.targetOffer?.id,
                    offerTableURL: pathname,
                    offerTablePage: urlFilters.page,
                    offerTablePageSize: urlFilters.pageSize,
                    filterModalURL: urlFilters.filterModalURL,
                  })
                ),
            })}
            loading={loading || isLoading}
            scroll={{ x: 990, y: height - 400 }}
          />
        </div>
      </Col>

      <NextPage
        currentPageOptions={urlFilters}
        nextPage={nextPage}
        committeeId={stringId}
        orderType={order}
      />
      <DecisionAskedModal
        data={decisionModal.data}
        open={decisionModal.visible}
        onCancel={() => setDecisionModal({ visible: false, data: null })}
      />
    </Row>
  );
};

export default Articles;
