import { useEffect, useState } from 'react';

import { Button, Card, Col, Modal, Row, Spin } from 'antd';
import { CalendarOutlined, FileDoneOutlined, FilePdfOutlined } from '@ant-design/icons';
import { FormattedMessage, useIntl } from 'react-intl';
import dayjs from 'dayjs';
import { openNotification, showErrorMessage } from 'utils';
import { useContracts } from 'useSWR';
import { updateAuthorContract } from 'fetcher';
import { CustomUpload, DatePicker, Form, RemoveButton } from 'components';
import { ShippingIcon } from 'components/icons';

export const OrderAgreementModal = ({ offer, open, close }) => {
  const { title, targetOffer } = offer;
  const { authors } = targetOffer;
  const [offerAuthor] = authors;

  const { formatMessage } = useIntl();
  const [form] = Form.useForm();

  const [busy, setBusy] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [authorContract, setAuthorContract] = useState(null);

  const { contracts: contract, isLoading } = useContracts({ id: targetOffer?.id, author: offerAuthor?.author?.id });

  useEffect(() => {
    if (!contract?.contract) return;
    setAuthorContract(contract);
  }, [contract]);

  async function submit(values) {
    setBusy(true);

    const { shippingDate } = values;
    const { articleOffer, author } = contract;

    const data = {
      articleOffer,
      author: author['@id'],
      shippingDate: shippingDate.toISOString(),
    };

    try {
      const uploaded = await updateAuthorContract(contract.id, data, fileList);

      openNotification({
        message: formatMessage({ id: 'appModule.actionSuccessfully' }),
      });

      setAuthorContract(uploaded);
      setFileList([]);
    } catch (error) {
      showErrorMessage(formatMessage({ id: 'article.form.fetchError' }, { error: `${error}` }));
    }

    setBusy(false);
  }

  async function removeAgreement() {
    setBusy(true);

    const { articleOffer, author } = authorContract;

    const data = {
      articleOffer,
      author: author['@id'],
      contract: null,
    };

    try {
      await updateAuthorContract(contract.id, data);

      openNotification({
        message: formatMessage({ id: 'appModule.actionSuccessfully' }),
      });

      setAuthorContract(null);
    } catch (error) {
      showErrorMessage(formatMessage({ id: 'article.form.fetchError' }, { error: `${error}` }));
    }

    setBusy(false);
  }

  return (
    <Modal
      footer={null}
      width={850}
      open={open}
      onCancel={close}
      maskClosable={false}
      onFinish={submit}
      className="gx-px-4"
      centered
    >
      <h1 className="gx-text-center">{title}</h1>
      <h2 className="gx-text-center gx-mb-4">
        <FormattedMessage id="appModule.agreement" />
      </h2>

      {isLoading ? (
        <Spin />
      ) : authorContract ? (
        <>
          <Card className="gx-text-center gx-mb-3">
            <div className="gx-d-flex gx-justify-content-between gx-align-items-center">
              <span className="gx-col gx-ml-5">{authorContract?.contract?.name}</span>

              <RemoveButton
                className="gx-mb-0"
                size="default"
                type="default"
                title={<FormattedMessage id="article.order.removeAgreement" />}
                onClick={removeAgreement}
                hideIcon
                danger
              />
            </div>
          </Card>

          <Row gutter={16}>
            <Col span={12}>
              <Card className="gx-mb-0">
                <div className="gx-d-flex gx-justify-content-between">
                  <div className="gx-d-flex gx-align-items-center">
                    <FileDoneOutlined className="gx-fs-xl gx-mr-2" />
                    <FormattedMessage id="article.order.agreementDate" />
                    {`: ${dayjs(authorContract?.contractDate).format('DD/MM/YYYY HH:mm')}`}
                  </div>

                  <CalendarOutlined className="gx-fs-xl" />
                </div>
              </Card>
            </Col>

            <Col span={12}>
              <Card className="gx-mb-0">
                <div className="gx-d-flex gx-justify-content-between">
                  <div className="gx-d-flex gx-align-items-center">
                    <ShippingIcon className="gx-fs-xl gx-mr-2" />
                    <FormattedMessage id="article.order.cargoDate" />
                    {`: ${dayjs(authorContract?.shippingDate).format('DD/MM/YYYY HH:mm')}`}
                  </div>

                  <CalendarOutlined className="gx-fs-xl" />
                </div>
              </Card>
            </Col>
          </Row>
        </>
      ) : (
        <Form
          form={form}
          layout="vertical"
          onFinish={submit}
        >
          <CustomUpload
            accept={['application/pdf']}
            icon={<FilePdfOutlined />}
            title={<FormattedMessage id="appModule.agreementFile" />}
            fileList={fileList}
            setFileList={setFileList}
            maxCount={1}
          />

          <Row
            className="gx-flex-row gx-my-3"
            gutter={32}
          >
            <Col span={12}>
              <Card className="gx-my-2">
                <div className="gx-d-flex gx-justify-content-between">
                  <div className="gx-d-flex gx-align-items-center">
                    <FileDoneOutlined className="gx-fs-xl gx-mr-2" />
                    <FormattedMessage id="article.order.agreementDate" />
                    {`: ${dayjs(contract?.contractDate).format('DD/MM/YYYY HH:mm')}`}
                  </div>

                  <CalendarOutlined className="gx-fs-xl" />
                </div>
              </Card>

              {/* NOT: Sözleşme alanının inputa dönüşmesi ihtimaliyle comment olarak bırakıldı */}
              {/* <Form.Item
                name="agreementDate"
                label={<FormattedMessage id="article.order.agreementDate" />}
                rules={[{ required: true, message: formatMessage({ id: 'appModule.requiredMessage' }) }]}
              >
                <DatePicker
                  locale={dayjs.locale('tr')}
                  format="DD/MM/YYYY"
                  className="gx-w-100"
                />
              </Form.Item> */}
            </Col>
            <Col span={12}>
              <Form.Item
                name="shippingDate"
                label={<FormattedMessage id="article.order.cargoDate" />}
                rules={[{ required: true, message: formatMessage({ id: 'appModule.requiredMessage' }) }]}
              >
                <DatePicker
                  locale={dayjs.locale('tr')}
                  format="DD/MM/YYYY"
                  className="gx-w-100"
                />
              </Form.Item>
            </Col>
          </Row>

          <Button
            type="primary"
            htmlType="submit"
            className="gx-my-1"
            loading={busy}
            disabled={fileList.length !== 1}
            block
          >
            <FormattedMessage id="appModule.save" />
          </Button>
        </Form>
      )}
    </Modal>
  );
};
