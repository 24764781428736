export const regexPass = /^(?=.*[a-zA-Z])(?=.*\d)|^(?=.*[a-zA-Z])(?=.*[#$^+=!*()@%&.])|^(?=.*[a-z])(?=.*[A-Z])|(?=.*\d)(?=.*[#$^+=!*()@%&.])/;

export const isamMail = /.@isam.org.tr$/gm;

export const yokAkademikBaseURL = 'https://akademik.yok.gov.tr/AkademikArama/AkademisyenGorevOgrenimBilgileri?islem=direct&authorId=';
export const yokAkademikRegex = /^https?:\/\/akademik\.yok\.gov\.tr.*authorId=.+/gi;

export const METADATA = {
  dahStatus: 'Tematik Ansiklopediler Eşgüdüm Kurulu:',
  dahDescription: 'Tematik Ansiklopediler Eşgüdüm Kurulu Karar Gerekçe Metni:',
  dahStatusChangeCommittee: 'Tematik Ansiklopediler Eşgüdüm Kurulumaddenin sorumlu heyetini değiştirdi.',
  committeeStatus: 'Sorumlu İlim Heyeti:',
  statusDescription: 'Sorumlu Heyet Gerekçe Metni:',
  aykStatus: 'Ansiklopedi Yayın Kurulu:',
  aykDescription: 'Ansiklopedi Yayın Kurulu Gerekçe Metni:',
  title: 'Teklif Edilen Madde Başlığı:',
  ownerCommittee: 'Teklifi Yapan İlim Heyeti:',
  otherCommittees: 'Sorumlu İlim Heyeti:',
  oldTitle: "İslam Ansiklopedisi'nde geçen başlık:",
  oldArticleWorkType: 'İkinci edisyon kapsamında yapılacak çalışma:',
  oldArticles: 'Zikredilen madde metinleri:',
  articleGroup: 'Teklif edilen maddenin grubu:',
  articleType: 'Teklif edilen maddenin türü:',
  committeeContribution: 'Katkıda bulunacak heyet:',
  committeeContributionType: 'Katkıda bulunacak heyetten beklenen çalışma:',
  committeeContributionOther: 'Katkıda bulunacak heyetten beklenen çalışma:',
  statusChangeCommittee: 'İlim Heyeti değiştirildi:',
  offerReason: 'Teklifin Gerekçesi değişitirildi.',
  articleTypeOther: 'Madde türünde değişiklik yapıldı.',
  dahRevertReasonAnswers: 'İade Sebebi:',
  offerTitle: '“Madde Adı” ile ilgili yönergeye uygunluk:',
  offerGroup: '“Madde Grubu” değişikliği:',
  offerType: '“Madde Türü” değişikliği:',
  offerSecondEditionSuitability: 'Tematik Ansiklopediler kapsamına uygunluk:',
  offerCommitteePriority: 'İlim heyeti önceliğine uygunluk:',
  offerCommitteeDetectAndChange: '“Katkıda Bulunacak İlim Heyeti” tespiti veya değişikliği:',
  offerCommitteeContributionChange: '“Katkıda Bulunacak İlim Heyeti”nin katkı türü değişikliği:',
  offerReasonSufficiency: 'Madde Tespit Formu’ndaki “Gerekçe” bölümünün yeterliliği:',
  committee: 'Katkıda Bulunacak Heyet',
  type: 'Katkıda bulunacak heyetten beklenen çalışma:',
  other: 'Katkıda bulunacak heyetten beklenen çalışma (Diğer) :',
  articleLength: '"Madde Hacmi" değişikliği',
  articleLengthReason: '"Madde Hacmi Gerekçesi" değişikliği',
  articleSupervisor: '"Madde Sorumlusu" değişikliği',
  contentFormFilled: 'Madde İçerik Formu dolduruldu.',
  author: 'Madde yazarlarında değişiklik yapıldı.',
  media: 'Ek dosyalarda değişiklik yapıldı.',
  offerContentWrongAuthorInfo: 'İçerik formunda belirtilen yazar bilgileri hatalıdır.',
  offerContentAuthorInfoNotEnough: '"Madde Yazarı" için sunulan gerekçe yeterli değildir.',
  offerContentArticleLengthReasonNotEnough: '"Madde Hacmi" için sunulan gerekçe yeterli değildir.',
  offerContentAdditionalInfoNotEnough: 'Gönderilen ek bilgi şekil, üslup ve içerik açısından uygun değildir.',
  offerContentMediaNotEnough: 'Gönderilen ek dosyalar madde tür ve içeriğine uygun değildir.',
};

export const ENTITY = {
  entity_edit: 'Madde formunda değişiklik yapıldı.',
  // entity_decision: 'Madde Hakkında Karar Verildi.',
  entity_decision: '',
};

export const ORDER_STATUS = {
  20: 'SB - Gündemde',
  21: 'Yazar - Onay',
  22: 'Yazar - Gündemde',
  23: 'SB - Madde Kontrol',
  24: 'IH - Madde Kontrol',
  25: 'IH - Madde Kontrol Görüş İstedi',
  26: 'IH - Madde Kontrol Yeniden Telif',
};

export const OFFER_STATUS = {
  0: 'İH - Gündemde',
  1: 'İH - Beklemede',
  // 2: 'İH Kabul Edildi',
  // 3: 'İH Reddedildi',
  // 4: 'İH - Heyeti Değiştirdi',
  5: 'TAEK - Gündemde', // eski sistemdeki ismi DAH - Gündemde
  6: 'TAEK - İnceleme', // eski sistemdeki ismi DAH - Beklemede
  // 7: 'DAH Kabul Edildi',
  // 8: 'DAH İade Edildi',
  // 9: 'DAH İlim Heyeti Değiştirildi',
  11: 'AYK - Gündemde',
  12: 'AYK - İnceleme',
  // 13: 'AYK Kabul Edildi',
  // 14: 'AYK İade Edildi',
  // 16: 'AYK Reddetti',
  17: 'Onaylandı',
  18: 'Reddedildi',
  19: 'İH - Taslak',
  ...ORDER_STATUS,
};

export const ilimHeyetiKarariSebebi = {
  2: "Teklif ettiğiniz başlığın Tematik Ansiklopediler'de madde olarak yer almasının gerekçesini kısaca izah ediniz:",
  0: 'İade Sebebi:',
  1: 'Bekletme Sebebi:',
  3: 'İlim Heyeti Değiştirme gerekçesi:',
};

export const oldArticleWorkTypes = {
  0: 'Yeniden Yazım',
  1: 'Güncelleme (İkmal)',
  2: 'Güncelleme (Kapsamlı Gözden Geçirme)',
  3: 'Güncelleme (Tashih)',
  4: "ATIF MADDESİ'nin ANSİKLOPEDİ MADDESİ olarak yazılması",
  5: "ATIF MADDESİ'nin SÖZLÜK MADDESİ olarak yazılması",
  6: 'Güncelleme (Görsel Güncellemesi)',
};

export const heyetKarari = {
  3: 'İADE',
  1: 'BEKLET',
  2: 'KABUL',
  4: 'İLİM HEYETİ DEĞİŞTİR',
};

export const heyetKarariMetin = {
  0: 'Gündemde',
  3: 'İade Edildi',
  1: 'Bekletiliyor',
  2: 'Kabul Edildi',
  4: 'İlim Heyeti Değiştirildi',
  5: 'Reddedildi',
  6: 'Havale',
  7: 'Taslak',
  8: 'Geri Çekti',
  9: 'Geri Çekildi',
  null: 'Heyet Kararı Verilmedi',
};

export const contributorWorks = {
  0: 'Bilgi, terminoloji ve kaynak kontrolü',
  1: 'Ek bilgi girişi',
  2: 'Ayrı bölüm yazımı',
};

export const COMI = ['committees', 'heads', 'secretaries', 'specialties'];

export const MISSION = {
  0: 'Heyet Başkanı',
  1: 'Heyet Sekreteri',
  3: 'Heyet Uzmanı',
  2: 'Heyet Üyesi',
};

export const formType = {
  dah: 'TAEK Kararı', // eski sistemdeki ismi Disiplinlerarası Heyet Kararı
  committee: 'İlim Heyeti Kararı',
  ayk: 'AYK Kararı',
  MTF: 'Madde Teklif Formu Kararı',
};

export const ROLE = {
  0: { color: 'green', title: 'Başkan' },
  1: { color: 'red', title: 'Sekreter' },
  3: { color: 'blue', title: 'Uzman' },
  2: { color: 'volcano', title: 'Üye' },
};

export const ROLES_DEF = {
  ROLE_ADMIN: 'Admin',
  ROLE_DAH: 'TAEK Üyesi',
  ROLE_COMMITTEE: 'İlim Heyeti Üyesi',
  ROLE_AYK: 'AYK Üyesi',
  ROLE_USER: 'Normal Kullanıcı',
  ROLE_ERESOURCE: 'Elektronik Kaynak Yöneticisi',
  ROLE_RESOURCE_USER: 'Elektronik Kaynak Kullanıcısı',
  ROLE_RESOURCE_AND_GUIDE_USER: 'Elektronik Kaynak ve Kılavuz Kullanıcısı',
  ROLE_SUPER_ADMIN: 'Süper Admin',
  ROLE_AUTHOR: 'Yazar',
  ROLE_ORDER_UNIT: 'Sipariş Birimi',
};
export const NOTE_TYPES = [
  [1, 'Telefon Görüşmesi'],
  [2, 'Email'],
];

export const STEP_DEF = {
  1: 'detection',
  3: 'order',
};

export const educationTypes = ['Lisans', 'Yüksek Lisans', 'Doktora'];

export const articleLength = ['50-250', '500-1500', '1500-3500', '3500-7000', '7000 +'];

export const opinionTypes = {
  approved: 'Onaylandı',
  rejected: 'Reddedildi',
  pending: 'Cevap bekleniyor',
  notAnswered: 'Cevap verilmedi',
};
export const afterCopyrightSteps = {
  0: [
    {
      title: 'Madde Başlığı Redaksiyonu',
    },
    {
      title: 'Metin İçi Veri Kontrolü',
    },
    {
      title: 'Madde Özü Redaksiyonu',
    },
    {
      title: 'Kimlik Kartı Redaksiyo',
    },
    {
      title: 'Üst Veri ve Kavram Ağacı Redaksiyonu',
    },
    {
      title: 'Üslub Redaksiyonu',
    },
  ],
  1: [
    {
      title: 'Atıf - Kaynaklar Redaksiyonu',
    },
    {
      title: 'Çeviri - Yazı Redaksiyonu',
    },
    {
      title: 'İmis Redaksiyonu',
    },
  ],
};
export const pullbackStatus = ['pending', 'approved', 'rejected'];

export const committeeDecisions = {
  approved: 2,
  rejected: 5,
  revert: 3,
  rewrite: 4,
};
/**
 * madde türleri kimlik kartının zorunlu olduğu alanlar
 * 7 -> Akım-Ekol
 * 1 -> Biyografi
 * 6 -> Kurum-Ağ
 * 8 -> Mekan-Yer
 * 19 -> Metin-Literatür
 * 20 -> Mimari-Sanat Eseri
 */
export const idCardRequired = [7, 1, 6, 8, 19, 20];

export const datePickerFormat = 'DD MMMM YYYY';
