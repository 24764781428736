import { Col, Row } from 'antd';

import AdditionalTime from './AdditionalTime';
import UploadAgreement from './UploadAgreement';

const StartProcessStepActions = ({ id, title, authors, disabled }) => {
  return (
    <Row gutter={24}>
      <Col
        xs={24}
        md={12}
      >
        <AdditionalTime
          title={title}
          offerId={id}
          authorsId={authors?.[0].id}
        />
      </Col>
      <Col
        xs={24}
        md={12}
      >
        <UploadAgreement
          disabled={disabled}
          offerId={id}
          authorId={authors[0].id}
        />
      </Col>
    </Row>
  );
};

export default StartProcessStepActions;
