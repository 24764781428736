import { Col, Row } from 'antd';
import AdditionalTime from 'components/ArticleOrder/status/AdditionalTime.jsx';
import UploadAgreement from 'components/ArticleOrder/status/UploadAgreement.jsx';

const UploadAgreementStepActions = ({ title, authors, offerId }) => {
  return (
    <Row
      gutter={24}
      className="modalBtnGrp"
    >
      <Col
        sm={24}
        md={12}
      >
        <AdditionalTime title={title} />
      </Col>
      <Col
        sm={24}
        md={12}
      >
        <UploadAgreement
          disabled={true}
          offerId={offerId}
          authorId={authors[0].id}
        />
      </Col>
    </Row>
  );
};
export default UploadAgreementStepActions;
