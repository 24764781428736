import React from 'react';

import { Button, Col, Row, Form } from 'antd';
import { FormattedMessage } from 'react-intl';

const ArticleFormAction = ({ activeTab, handleActiveTab, buttonLoading }) => {
  const form = Form.useFormInstance();

  return (
    <Row
      justify="end"
      style={{
        flexDirection: 'row',
      }}
      gutter={6}
    >
      <Col>
        {activeTab === '2' && (
          <Button
            style={{ width: 100, float: 'right' }}
            className="gx-mt-2"
            onClick={() => handleActiveTab('1')}
          >
            <FormattedMessage id="goBack" />
          </Button>
        )}
      </Col>
      <Col>
        <Button
          style={{ width: 100, float: 'right' }}
          type="primary"
          onClick={() => {
            if (activeTab === '1') {
              form.validateFields().then(() => handleActiveTab('2'));
            } else {
              form.submit();
            }
          }}
          className="gx-mt-2"
          loading={buttonLoading}
        >
          <FormattedMessage id="appModule.save" />
        </Button>
      </Col>
    </Row>
  );
};

export default ArticleFormAction;
