import { Card, Form } from 'antd';
import { Input } from 'components';
import { FormattedMessage, useIntl } from 'react-intl';
import { CommitteeMemberSelect } from 'components/Select';

const { TextArea } = Input;

const DetectionResponsible = ({ isDetailPage = false }) => {
  const { formatMessage } = useIntl();

  const form = Form.useFormInstance();

  // bu alanlar formun verilerindeki değişiklikleri anlık olarak izlemek için kullanılıyor
  const ownerCommittee = Form.useWatch('ownerCommittee', form);
  const articleGroup = Form.useWatch('articleGroup', form);

  return (
    <>
      <Card className="gx-mb-3">
        <Form.Item
          shouldUpdate={(prevValues, currentValues) =>
            prevValues.ownerCommittee !== currentValues.ownerCommittee && !isDetailPage && form.setFieldsValue({ articleSupervisor: undefined })
          }
          label={formatMessage({ id: 'article.detail.articleSupervisor' })}
          required
          name="articleSupervisor"
          rules={[
            {
              required: true,
              message: formatMessage({ id: 'appModule.requiredMessage' }),
            },
          ]}
        >
          <CommitteeMemberSelect committeeID={ownerCommittee && ownerCommittee?.split('/')[3]} />
        </Form.Item>
      </Card>
      <Form.Item shouldUpdate>
        {() => {
          return (
            !articleGroup?.includes('2') && (
              <Card
                style={{ marginBottom: 12, border: 0 }}
                hoverable
              >
                <Form.Item
                  name="articleLengthReason"
                  label={formatMessage({ id: 'article.detail.articleLengthReason' })}
                  className="gx-mb-2"
                  rules={[
                    {
                      min: 100,
                      message: formatMessage({ id: 'article.reasonLengthMessage' }),
                      whitespace: true,
                    },
                  ]}
                  normalize={(value) => (value.trim() === '' ? null : value)}
                >
                  <TextArea
                    showCount
                    rows={5}
                  />
                </Form.Item>
              </Card>
            )
          );
        }}
      </Form.Item>

      <Card
        style={{ marginBottom: 12, border: 0, paddingBottom: 25 }}
        hoverable
      >
        <Form.Item
          name="detectReason"
          label={<FormattedMessage id="article.form.detectReason" />}
          labelfor={'detectReason'}
          rules={[
            {
              min: 100,
              message: formatMessage({
                id: 'article.reasonLengthMessage',
              }),
            },
          ]}
          normalize={(value) => (value.trim() === '' ? null : value)}
        >
          <TextArea
            showCount
            rows={5}
            placeholder={formatMessage({
              id: 'article.status.DecisionReasonPlaceHolder',
            })}
          />
        </Form.Item>
      </Card>
    </>
  );
};

export default DetectionResponsible;
