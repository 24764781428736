export const taekId = 2; // -> 18;
export const aykId = 1; // -> 19;

export const higherCommittee = [aykId, taekId];

/**
 *   Onaylandı -> 17
 *   Reddedildi -> 18
 *   İH - Taslak -> 19
 *   SB-Gündemde -> 20
 *   Yazar-Onay -> 21
 *   Yazar-Gündemde -> 22
 *   SB-Madde Kontrol -> 23
 *   IH-Madde Kontrol -> 24
 *   IH-Madde Kontrol Görüş İstedi -> 25
 *   IH-Madde Kontrol Yeniden Telif -> 26
 *   Telif-sonrasi -> 27
 */
export const article_order_status = {
  Onaylandı: 17,
  Reddedildi: 18,
  'IH - Taslak': 19,
  'SB-Gündemde': 20,
  'Yazar-Onay': 21,
  'Yazar-Gündemde': 22,
  'SB-Madde Kontrol': 23,
  'IH-Madde Kontrol': 24,
  'IH-Madde Kontrol Görüş İstedi': 25,
  'IH-Madde Kontrol Yeniden Telif': 26,
  'Telif Sonrasi': 27,
};

/**
 * İncele -> 1
 *  Kabul -> 2
 * İade -> 3
 * Ret -> 5
 * Havale -> 6
 */
export const article_detection_decisions = {
  incele: 1,
  kabul: 2,
  iade: 3,
  red: 5,
  havale: 6,
};
