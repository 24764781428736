import apiInstance from './api';

const disposalContractApi = apiInstance.injectEndpoints({
  endpoints: (builder) => ({
    getDisposalContracts: builder.query({
      query: (id) => `/disposal_contracts/offer/${id}`,

      providesTags: ['DisposalContracts'],
    }),
    updateDisposalContactConfig: builder.mutation({
      query: ({ id, body }) => ({ url: `/disposal_contracts/${id}`, method: 'PUT', body }),
      invalidatesTags: ['DisposalContracts'],
    }),
    calculateDisposalContactPrice: builder.mutation({
      query: ({ body }) => ({ url: '/disposal_contracts/calculator', method: 'POST', body }),
    }),
    createDisposalContract: builder.mutation({
      query: ({ body }) => ({ url: '/disposal_contract_contributions', method: 'POST', body }),
      invalidatesTags: ['DisposalContracts'],
    }),
    updateDisposalContract: builder.mutation({
      query: ({ id, body }) => ({ url: `/disposal_contract_contributions/${id}`, method: 'PUT', body }),
      invalidatesTags: ['DisposalContracts'],
    }),
  }),
  overrideExisting: true,
});

export default disposalContractApi;
