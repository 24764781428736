import { useState } from 'react';

import { Dropdown } from 'antd';
import { ClockCircleOutlined, CloseCircleOutlined, EllipsisOutlined, FilePdfOutlined, PlayCircleOutlined, ReadOutlined } from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import { OrderCancelModal } from './CancelModal';
import { OrderMailModal } from './MailModal';
import { OrderAgreementModal } from './AgreementModal';

export const ArticleOrderActions = ({ offer, author, mutateArticles }) => {
  const [modals, setModals] = useState({
    cancel: false,
    mail: false,
    agreement: false,
  });

  if (!author && (offer?.targetOffer?.step < 3 || offer?.step < 3)) return '-';

  const actionMenuItems = author
    ? [
        {
          key: 'approve',
          label: (
            <Link to={`/madde-detay/${offer?.targetOffer?.id}`}>
              <FormattedMessage id="appModule.approveOffer" /> / <FormattedMessage id="appModule.reject" />
            </Link>
          ),
          icon: <PlayCircleOutlined className="gx-mr-2" />,
          disabled: offer?.targetOffer?.status !== 21,
        },
        {
          key: 'agreement',
          label: <FormattedMessage id="appModule.agreement" />,
          icon: <FilePdfOutlined className="gx-mr-2" />,
          disabled: offer?.targetOffer?.status !== 21,
        },
        {
          key: 'article',
          label: <FormattedMessage id="appModule.uploadArticle" />,
          icon: <ReadOutlined className="gx-mr-2" />,
          disabled: offer?.targetOffer?.status !== 22,
        },
      ]
    : [
        {
          key: 'start',
          label: (
            <Link to={`/madde-detay/${offer?.targetOffer?.id}`}>
              <FormattedMessage id="appModule.startProcess" />
            </Link>
          ),
          disabled: offer?.targetOffer?.status !== 20,
          icon: <PlayCircleOutlined className="gx-mr-2" />,
        },
        {
          key: 'cancel',
          label: <FormattedMessage id="appModule.cancelAction" />,
          icon: <CloseCircleOutlined className="gx-mr-2" />,
          onClick: () => setModals((prev) => ({ ...prev, cancel: true })),
        },
        {
          key: 'mail',
          label: <FormattedMessage id="appModule.sendMail" />,
          icon: <ClockCircleOutlined className="gx-mr-2" />,
          onClick: () => setModals((prev) => ({ ...prev, mail: true })),
        },
        {
          key: 'agreement',
          label: <FormattedMessage id="appModule.agreement" />,
          disabled: offer?.targetOffer?.status !== 22,
          icon: <FilePdfOutlined className="gx-mr-2" />,
          onClick: () => setModals((prev) => ({ ...prev, agreement: true })),
        },
      ];

  const menu = actionMenuItems.map((item) => ({
    key: item.key,
    label: item.label,
    icon: item.icon,
    onClick: item.onClick,
    disabled: item.disabled,
  }));

  return (
    <>
      <Dropdown
        type="text"
        menu={{
          items: menu,
        }}
        trigger={['click']}
      >
        <EllipsisOutlined rotate={90} />
      </Dropdown>
      {/* modal yapısı gereği ekranda gözükmese de içerisindeki istekler çalıştığı ve modal içinde useEffect içerisinde false değer de return etmek istemediğim için bu şekilde bir yapı oluşturulmuştur. */}
      {offer.targerOffer && (
        <OrderCancelModal
          offer={offer}
          open={modals.cancel}
          close={() => mutateArticles() && setModals((prev) => ({ ...prev, cancel: false }))}
        />
      )}
      <OrderMailModal
        offer={offer}
        open={modals.mail}
        close={() => setModals((prev) => ({ ...prev, mail: false }))}
      />
      {/* modal yapısı gereği ekranda gözükmese de içerisindeki istekler çalıştığı ve modal içinde useEffect içerisinde false değer de return etmek istemediğim için bu şekilde bir yapı oluşturulmuştur. */}
      {offer.targerOffer && (
        <OrderAgreementModal
          offer={offer}
          open={modals.agreement}
          close={() => setModals((prev) => ({ ...prev, agreement: false }))}
        />
      )}
    </>
  );
};
