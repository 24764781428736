import React from 'react';

import { Card } from 'antd';
import dayjs from 'dayjs';
import { useGetCurrentUserQuery } from 'services';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';

const handleOrderStatusText = (track, status) => {
  switch (track) {
    case 'pending':
      return status === 21 ? 'pendingAuthorApproval' : 'pendingAuthorContent';
    case 'approved':
      return status === 21 ? 'authorWaitAccepted' : 'authorAccepted';
    case 'rejected':
      return 'authorRejected';
    case 'notAnswered':
      return 'deadlinePassed';
    default:
      break;
  }
};

const ArticleOrderStatusItem = ({ content, authorName, offerStatus, finishDate, opinionDate, track }) => {
  const { data: current_user } = useGetCurrentUserQuery();
  return (
    // her bir durum için hide prop u yollanmadığından dolayı ve undefined falsy değer olduğundan dolayı bu şekilde bir kontrol yapıldı
    <Card>
      <div className="gx-d-flex gx-align-items-center gx-mb-3">
        <span className="gx-mr-3 gx-fs-xxl">{content[track]?.icon}</span>
        {current_user.isAdmin || current_user.isOrderUnit ? (
          <span className="gx-fs-xl">
            <FormattedMessage
              id={`${content[track]?.key}.${handleOrderStatusText(track, offerStatus)}.title`}
              values={{
                date: dayjs(finishDate).format('DD/MM/YYYY'),
              }}
            />
          </span>
        ) : (
          current_user.isAuthor && (
            <span className="gx-fs-xl">
              <FormattedMessage
                id={`${content[track]?.key}.${handleOrderStatusText(track, offerStatus)}.authorTitle`}
                values={{
                  date: dayjs(opinionDate || finishDate).format('DD/MM/YYYY'),
                }}
              />
            </span>
          )
        )}
        {authorName && <div className="gx-ml-1 gx-fs-xl">({authorName})</div>}
      </div>
      <FormattedHTMLMessage
        id={`${content[track]?.key}.${handleOrderStatusText(track, offerStatus)}.description`}
        values={{
          date: dayjs(finishDate).format('DD/MM/YYYY'),
          diff: dayjs(finishDate).diff(dayjs(), 'days'),
        }}
      />
      <div className={content[track]?.hide !== undefined && !content[track]?.hide ? 'gx-display-none' : 'gx-px-2'}>{content[track]?.component}</div>
    </Card>
  );
};

export default ArticleOrderStatusItem;
