import { useEffect } from 'react';

import { message, Upload, Form } from 'antd';
import { Input } from 'components';
import { useIntl } from 'react-intl';
import { useGetArticleContentQuery, useSendArticleContentMutation } from 'services';
import { checkFilesSize, chunkUploadMedia } from 'utils';
import { InboxOutlined } from '@ant-design/icons';
import UploadedFile from 'components/AddNewArticle/UploadedFile';

const { TextArea } = Input;

const CommitteeOpinionExtras = ({ offerID }) => {
  const form = Form.useFormInstance();
  const { formatMessage } = useIntl();
  const [sendArticleContent] = useSendArticleContentMutation();
  const { data } = useGetArticleContentQuery(
    {
      offerID,
    },
    {
      skip: !offerID,
    }
  );
  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        additionalFile: data.contents.find((item) => item.type === 3),
        identityFile: data.contents.find((item) => item.type === 4),
      });
    }
  }, [data, form]);

  const handleCustomRequest = async ({ file, onSuccess, onError, formItemName, itemType }) => {
    if (file.size === 0) {
      return message.error('Dosya boş olamaz');
    }
    checkFilesSize([file]);
    try {
      const [res] = await chunkUploadMedia('articleContent', [file]);
      const { error } = await sendArticleContent({
        offerID,
        body: {
          media: [`/api/media/${res.media.id}`],
          type: itemType,
        },
      });
      if (error) {
        form.resetFields([formItemName]);
        onError('error');
      }
      onSuccess('done');
    } catch (error) {
      form.resetFields([formItemName]);
      onError('error');
    }
  };

  const handleChange = (info) => {
    if (info.file.status === 'done') {
      message.destroy();
      message.success('Dosya Başarıyla yüklendi');
    } else if (info.file.status === 'error') {
      message.destroy();
      message.error('Dosya yüklenirken bir hata oluştu');
    } else if (info.file.status === 'uploading') {
      message.loading('Dosya yükleniyor', 0);
    }
  };

  const additionalFile = Form.useWatch('additionalFile', form);
  const identityFile = Form.useWatch('identityFile', form);
  return (
    <>
      <Form.Item
        label="Madde Dosyası"
        className="gx-mb-4"
        name="additionalFile"
      >
        {additionalFile?.media?.[0] ? (
          <UploadedFile
            content={additionalFile?.media[0]?.name}
            id={additionalFile.id}
          />
        ) : (
          <Upload.Dragger
            onChange={handleChange}
            maxCount={1}
            accept={['.docx', '.doc', '.pdf']}
            customRequest={({ file, onSuccess, onError }) => handleCustomRequest({ file, onSuccess, onError, formItemName: 'additionalFile', itemType: 3 })}
            showUploadList={false}
          >
            <>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">Dosyayı sürükleyip bırakın ya da tıklayın</p>
              <p className="ant-upload-hint">Dosya boyutu 25MB'ı geçmemelidir.</p>
            </>
          </Upload.Dragger>
        )}
      </Form.Item>
      <Form.Item
        label="Kimlik Dosyası"
        className="gx-mb-4"
        name="identityFile"
      >
        {identityFile?.media?.[0] ? (
          <UploadedFile
            content={identityFile?.media[0]?.name}
            id={identityFile.id}
          />
        ) : (
          <Upload.Dragger
            maxCount={1}
            onChange={handleChange}
            accept={['.docx', '.doc']}
            customRequest={async ({ file, onSuccess, onError }) => handleCustomRequest({ file, onSuccess, onError, formItemName: 'identityFile', itemType: 4 })}
            showUploadList={false}
          >
            <>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">Dosyayı sürükleyip bırakın ya da tıklayın</p>
              <p className="ant-upload-hint">Dosya boyutu 25MB'ı geçmemelidir.</p>
            </>
          </Upload.Dragger>
        )}
      </Form.Item>

      <Form.Item
        name="mediaOfferDescription"
        className="gx-mb-4"
        rules={[
          {
            min: 50,
            message: formatMessage({
              id: 'article.reasonLengthMessageShort',
            }),
          },
        ]}
        normalize={(value) => (value.trim() === '' ? undefined : value)}
      >
        <TextArea
          rows={8}
          placeholder="Medya teklifi ile ilgili görüş belirtiniz"
          showCount
        />
      </Form.Item>
    </>
  );
};

export default CommitteeOpinionExtras;
