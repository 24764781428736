import { Col, Row, Table } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import dayjs from 'dayjs';
import { useWindowSize, useFilters } from 'hooks';
import { Input, Title } from 'components';
import { ArticleOrderActions, AuthorFilters } from 'components/ArticleOrder';
import { TableAlert } from 'components/ArticlesTable';
import { Link } from 'react-router-dom';
import { useGetAuthorArticlesQuery } from 'services';

const { Search } = Input;

const AuthorArticles = () => {
  const { formatMessage } = useIntl();
  const { width, height } = useWindowSize();

  const { filters, setFilters, filtered } = useFilters({
    page: 1,
    pageSize: 20,
  });

  const { data: articles, isLoading: articlesLoading } = useGetAuthorArticlesQuery(filters);

  const pagination = {
    defaultCurrent: 1,
    defaultPageSize: 20,
    current: filters.page,
    pageSize: filters.pageSize,
    total: articles?.['hydra:totalItems'] ?? 1,
    position: ['bottomRight'],
    responsive: true,
    showSizeChanger: true,
    pageSizeOptions: [10, 20, 50, 100],
    showTotal: (total) => `${formatMessage({ id: 'table.totalItem' }, { type: formatMessage({ id: 'article' }) })} ${total}`,
  };

  const handleTableChange = (tablePagination) => {
    const { current, pageSize } = tablePagination;

    setFilters({
      page: pageSize === filters.pageSize ? current : 1,
      pageSize,
    });
  };

  const columns = [
    {
      title: <FormattedMessage id="article.detail.FormNumber" />,
      dataIndex: 'articleCodeFormatted',
      align: 'center',
      width: 100,
      fixed: width > 575 ? 'left' : null,
    },
    {
      title: <FormattedMessage id="article.detail.articleTitle" />,
      dataIndex: 'title',
      width: 450,
      fixed: width > 575 ? 'left' : null,
      render: (title, record) => <Link to={`/madde-detay/${record.targetOffer.id}`}>{title}</Link>,
    },
    {
      title: <FormattedMessage id="article.filter.offeredAuthor" />,
      dataIndex: 'authors',
      align: 'center',
      width: 130,
      render: (authors) => (authors ? authors?.map(({ author }) => <div key={author && author.id}>{author.user.fullName}</div>) : '-'),
    },
    {
      title: <FormattedMessage id="article.filter.date" />,
      dataIndex: 'createdAt',
      align: 'center',
      width: 120,
      render: (updatedAt) => dayjs(updatedAt).format('DD/MM/YYYY - HH:mm'),
    },
    {
      title: <FormattedMessage id="article.filter.generalSituation" />,
      dataIndex: 'status',
      className: `article-table-general-situation`,
      align: 'center',
      width: 150,
      render: (status) => <TableAlert karar={status} />,
    },
    {
      title: <FormattedMessage id="appModule.action" />,
      key: 'action',
      align: 'center',
      width: 60,
      render: (_, data) => (
        <ArticleOrderActions
          offer={data}
          author
        />
      ),
    },
  ].filter((col) => !col.hidden);

  return (
    <>
      <Title>{formatMessage({ id: 'sidebar.authorArticleOffers' })}</Title>

      <h1 className="gx-h1-lg">
        <FormattedMessage id="sidebar.authorArticleOffers" />
      </h1>

      <Row>
        <Col
          xs={18}
          md={11}
        >
          <Search
            name="offerName"
            placeholder={formatMessage({ id: 'article.table.search' })}
            allowClear
            enterButton
          />
        </Col>
        <Col>
          <AuthorFilters
            filters={filters}
            setFilters={setFilters}
            filtered={filtered}
          />
        </Col>
      </Row>
      <Table
        bordered
        columns={columns}
        dataSource={articles?.['hydra:member']}
        rowKey={(record) => record['@id']}
        loading={articlesLoading}
        scroll={{ x: 990, y: height - 410 }}
        pagination={pagination}
        onChange={handleTableChange}
      />
    </>
  );
};

export default AuthorArticles;
