import { DownloadOutlined, FileExclamationOutlined, NodeExpandOutlined } from '@ant-design/icons';
import { Card, Col, Row, Tooltip } from 'antd';
import { useLazyDownloadArticleOfferContentTemplateQuery } from 'services';
import { downloadBlob, idCardRequired } from 'utils';

const ContentTemplate = ({ id, articleTypeID }) => {
  const [downloadArticleOfferContentTemplate] = useLazyDownloadArticleOfferContentTemplateQuery();
  const templates = [
    {
      id: 1,
      label: 'Madde Şablonu',
      // bu şablon her maddede olmak zorunda o yüzden direk true olarak set edildi
      isRequiredArticleType: true,
      link: 'content',
    },
    {
      id: 2,
      label: 'Kimlik Şablonu',
      isRequiredArticleType: idCardRequired.includes(articleTypeID),
      link: 'identification',
    },
  ];

  const download = async (type) => {
    const downloaded = await downloadArticleOfferContentTemplate({
      id,
      type,
    });
    downloadBlob(downloaded.data.data, downloaded.data.fileName);
  };

  return (
    <div className="gx-mt-4">
      <h2 className="gx-mb-4">
        Şablonlar
        <span className="gx-fs-md"> ({templates?.length}) </span>
      </h2>
      <Row gutter={24}>
        {templates.map((item) => (
          <Col
            key={item.id}
            sm={24}
            md={templates.length > 1 ? 12 : 24}
          >
            <Card className="gx-py-1 gx-mb-3">
              <div className="gx-d-flex gx-align-items-center gx-justify-content-between">
                <div className="gx-d-flex gx-align-items-center">
                  <span className=" gx-fs-xxl">
                    <NodeExpandOutlined />
                  </span>
                  <span className="gx-ml-3">{item.label}</span>
                </div>
                {item.isRequiredArticleType ? (
                  <a
                    className="gx-ml-2"
                    onClick={() => download(item.link)}
                  >
                    <DownloadOutlined className="gx-fs-xl" />
                  </a>
                ) : (
                  <Tooltip title="Madde türünün Kimlik Kartı şablonu bulunmamaktadır.">
                    <a className="gx-ml-2">
                      <FileExclamationOutlined className="gx-fs-xl" />
                    </a>
                  </Tooltip>
                )}
              </div>
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default ContentTemplate;
