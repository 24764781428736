import UserForm from 'module/Form/UserForm';

import { useState } from 'react';

import { Button, Drawer, Form } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { adminUserAdd } from 'fetcher';
import { openNotification } from 'utils';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { fetchError } from 'slices/commonSlice';

const UserAddDrawer = ({ ...other }) => {
  const [form] = Form.useForm();
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const onFinish = async (values) => {
    setLoading(true);
    try {
      await adminUserAdd(values);
      openNotification({
        message: formatMessage({ id: 'appModule.actionSuccessfully' }),
      });
      setTimeout(() => {
        navigate('/uye-islemleri/uye-listesi');
      }, 1500);
    } catch (error) {
      dispatch(
        fetchError(
          formatMessage(
            {
              id: 'article.form.fetchError',
            },
            { error: `${error}` }
          )
        )
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Drawer
      style={{
        overflow: 'hidden',
      }}
      headerStyle={{ padding: 0 }}
      footerStyle={{
        padding: 0,
      }}
      width={750}
      bodyStyle={{ paddingTop: '1rem', paddingLeft: 0, paddingRight: 0, overflow: 'hidden' }}
      title={<FormattedMessage id="userAdd.userAdd" />}
      footer={
        <Button
          form="userAddForm"
          block
          loading={loading}
          type="primary"
          htmlType="submit"
        >
          <FormattedMessage id="userAdd.userAdd" />
        </Button>
      }
      destroyOnClose
      {...other}
    >
      <Form
        id="userAddForm"
        form={form}
        onFinish={onFinish}
        layout="vertical"
      >
        <UserForm vertical />
      </Form>
    </Drawer>
  );
};

export default UserAddDrawer;
