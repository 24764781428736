import { useState } from 'react';

import { Button, Card, Checkbox, Modal, Form, Steps, Input, Row, Col } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { openNotification } from 'utils';
import { useDispatch } from 'react-redux';
import { fetchError } from 'slices/commonSlice';
import { useGetArticleOfferProcessQuery, useGetContractQuery, useGetCurrentUserQuery, useSendAuthorOpinionMutation } from 'services';
import { CircularProgress } from 'components/CircularProgress';

import { ArticleOrderSummary } from '../ArticleOrderSummary';

const { TextArea } = Input;
/**
 * Yazarın madde ile ilgli karar verme işlemleri
 */
export const AuthorDecision = ({ offer }) => {
  const { data: contract, isFetching } = useGetContractQuery(offer && offer.id);
  const { data: processData } = useGetArticleOfferProcessQuery(offer && offer.id);
  const { data: current_user } = useGetCurrentUserQuery();
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const [form] = Form.useForm();
  const [agreementForm] = Form.useForm();
  const dispatch = useDispatch();
  const [sendAuthorOpinion] = useSendAuthorOpinionMutation();
  const [busy, setBusy] = useState(false);
  const [modals, setModals] = useState({
    accept: false,
    reject: false,
  });

  const ownerAuthorContentID = processData?.find((item) => item.author.user.id === current_user.id && item.opinion === 'pending')?.id;

  const [current, setCurrent] = useState(0);

  const AuthorNoteForm = () => (
    <Form
      form={form}
      onFinish={onFinish}
    >
      <Form.Item
        name="description"
        className="gx-mb-4"
        rules={[
          { max: 1000, message: formatMessage({ id: 'article.order.authorNoteLengthError' }) },
          {
            min: 50,
            message: 'En az 50 karakter girmelisiniz.',
          },
        ]}
        normalize={(value) => (value.trim() === '' ? undefined : value)}
      >
        <TextArea
          rows={6}
          placeholder={formatMessage({ id: `article.order.author${modals.accept ? 'Note' : 'Reject'}Placeholder` })}
          showCount
        />
      </Form.Item>
      <div className="gx-d-flex gx-pt-2">
        {modals.accept && current === 1 ? (
          <Button
            className="gx-text-uppercase gx-mb-2"
            onClick={() => setCurrent(0)}
            block
          >
            <FormattedMessage id="goBack" />
          </Button>
        ) : (
          <Button
            className="gx-text-uppercase gx-mb-2"
            onClick={() => setModals({ accept: false, reject: false })}
            disabled={busy}
            block
          >
            <FormattedMessage id="appModule.cancel" />
          </Button>
        )}

        <Button
          type="primary"
          htmlType="submit"
          className="gx-text-uppercase gx-mb-2"
          loading={busy}
          block
        >
          <FormattedMessage id={`appModule.${modals.accept ? 'approveOffer' : 'reject'}`} />
        </Button>
      </div>
    </Form>
  );

  if (isFetching) return <CircularProgress />;

  const steps = [
    {
      title: formatMessage({ id: 'appModule.acceptAgreement' }),
      content: (
        <>
          <Card className="gx-p-2 gx-mb-3">
            <iframe
              className="gx-w-100 gx-border-0"
              srcDoc={contract && contract.html}
              height={250}
            />
          </Card>
          <Form
            id="agreementForm"
            form={agreementForm}
            onFinish={() => setCurrent(1)}
          >
            <Form.Item
              valuePropName="checked"
              rules={[
                {
                  required: true,
                  validator: (_, value) => (value === true ? Promise.resolve() : Promise.reject('Lütfen sözleşmeyi kabul edin.')),
                },
              ]}
              name="accept"
            >
              <Checkbox>
                <FormattedMessage id="appModule.acceptAgreement" />
              </Checkbox>
            </Form.Item>
          </Form>
          <div className="gx-d-flex gx-pt-2">
            <Button
              className="gx-text-uppercase gx-mb-2"
              onClick={() => setModals({ accept: false, reject: false })}
              disabled={busy}
              block
            >
              <FormattedMessage id="appModule.cancel" />
            </Button>

            <Button
              type="primary"
              form="agreementForm"
              htmlType="submit"
              className="gx-text-uppercase gx-mb-2"
              block
            >
              <FormattedMessage id="appModule.approveOffer" />
            </Button>
          </div>
        </>
      ),
    },
    {
      title: formatMessage({ id: 'appModule.accept' }),
      content: (
        <>
          <h1 className="gx-text-center gx-text-uppercase">
            <FormattedMessage id="appModule.accept" />
          </h1>

          <p className="gx-text-center gx-my-4">
            <FormattedMessage id="article.order.authorAcceptDescription" />
          </p>

          <ArticleOrderSummary offer={offer} />

          <AuthorNoteForm />
        </>
      ),
    },
  ];

  const items = steps.map((step) => ({
    key: step.title,
    title: step.title,
  }));

  const onFinish = async (values) => {
    setBusy(true);

    const id = ownerAuthorContentID;
    const opinion = {
      opinion: modals.accept ? 'approved' : 'rejected',
      ...values,
      ...(modals.reject && values),
    };

    try {
      const { error } = await sendAuthorOpinion({ id, body: opinion });

      if (error) {
        setBusy(false);
        return dispatch(fetchError(formatMessage({ id: 'article.form.fetchError' }, { error: error.status })));
      }

      openNotification({
        message: formatMessage({ id: 'appModule.actionSuccessfully' }),
      });

      navigate('/maddelerim');
    } catch (error) {
      dispatch(fetchError(formatMessage({ id: 'article.form.fetchError' }, { error })));
    }

    setBusy(false);
  };

  return (
    <>
      <Row gutter={[24, 16]}>
        <Col
          xs={24}
          md={12}
        >
          <Button
            className="gx-text-uppercase gx-my-0"
            onClick={() => setModals((prev) => ({ ...prev, reject: true }))}
            disabled={busy}
            block
          >
            <FormattedMessage id="appModule.reject" />
          </Button>
        </Col>
        <Col
          xs={24}
          md={12}
        >
          <Button
            type="primary"
            className="gx-text-uppercase gx-my-0"
            onClick={() => setModals((prev) => ({ ...prev, accept: true }))}
            loading={busy}
            block
          >
            <FormattedMessage id="appModule.approveOffer" />
          </Button>
        </Col>
      </Row>

      <Modal
        footer={null}
        width={600}
        open={modals.reject}
        onCancel={() => setModals((prev) => ({ ...prev, reject: false }))}
        maskClosable={false}
        centered
      >
        <h1 className="gx-text-center gx-text-uppercase">
          <FormattedMessage id="appModule.rejected" />
        </h1>

        <p className="gx-text-center gx-my-4 gx-font-weight-medium">
          <FormattedMessage id="appModule.rejectQuestion" />
        </p>

        <AuthorNoteForm />
      </Modal>

      <Modal
        footer={null}
        width={850}
        open={modals.accept}
        onCancel={() => setModals((prev) => ({ ...prev, accept: false }))}
        maskClosable={false}
        destroyOnClose
        centered
      >
        <Steps
          className="gx-my-4 gx-px-5"
          current={current}
          labelPlacement="vertical"
          items={items}
        />
        {steps[current].content}
      </Modal>
    </>
  );
};
