import { useGetCurrentUserQuery } from 'services';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ user, children }) => {
  const { data: current_user } = useGetCurrentUserQuery();

  // bu kontrol prop olarak gelen user mevcut kullanıcıyla aynı değilse mevcut kullanıcı yetkili rollerden birine sahip mi diye kontrol ediyor
  const witchUserShowArea = current_user.isAdmin || current_user.isOrderUnit || current_user.isComittee || current_user.isDAH || current_user.isAYK || false;
  const currentAuthorID = current_user?.author?.id;
  const isCurrentAuthor = user?.includes(currentAuthorID) || false;
  if (isCurrentAuthor || witchUserShowArea) {
    return children;
  }

  return <Navigate to="/" />;
};

export default ProtectedRoute;
