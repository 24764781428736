import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { getCookies, removeCookies, setCookies } from '../fetcher/tokenHandler';

const baseURL = `${process.env.REACT_APP_PUBLIC_BASE_URL}/api`;

const baseQuery = fetchBaseQuery({
  baseUrl: baseURL,
  prepareHeaders: (headers) => {
    const { token, xSwitchUser } = getCookies();
    if (token) {
      headers.set('authorization', `Bearer ${token}`);
    }

    if (xSwitchUser) {
      headers.set('x-switch-user', xSwitchUser);
    }
    return headers;
  },
});

let isRefreshing = false;
let refreshSubscribers = [];

const onRefreshed = (token) => {
  refreshSubscribers.forEach((cb) => cb(token));
};

const addRefreshSubscriber = (callback) => {
  refreshSubscribers.push(callback);
};

const customBaseQuery = async (args, api, extra) => {
  let result = await baseQuery(args, api, extra);
  const unauthError = result.error && result.error.status === 401;
  const { token, refreshToken } = getCookies();
  if (unauthError && !token && !refreshToken && window.location.pathname !== '/giris') {
    sessionStorage.setItem('returnURL', window.location.pathname);
    window.location.href = '/giris';
  } else if (unauthError && token && !refreshToken) {
    sessionStorage.setItem('returnURL', window.location.pathname);
    removeCookies();
    window.location.href = '/giris';
  } else if (unauthError && refreshToken) {
    if (!isRefreshing) {
      isRefreshing = true;
      const rs = await fetch(`${baseURL}/refresh_token`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          refreshToken,
        }),
      });
      isRefreshing = false;
      if (rs.ok) {
        const { token, refreshToken: newRs } = await rs.json();
        onRefreshed(token);
        refreshSubscribers = [];
        setCookies(token, newRs);
        result = await baseQuery(args, api, extra);
      } else {
        removeCookies();
        window.location.href = '/giris';
      }
    } else {
      const tokenPromise = new Promise((resolve) => {
        addRefreshSubscriber(resolve);
      });
      await tokenPromise;
      result = await baseQuery(args, api, extra);
    }
  }
  return result;
};

const apiInstance = createApi({
  reducerPath: 'api',
  baseQuery: customBaseQuery,
  endpoints: () => ({}),
  tagTypes: [
    'Activities',
    'AcedemicTitles',
    'AuthorArticles',
    'AuthorTasks',
    'OrderRevertReasons',
    'Projects',
    'Roles',
    'StiplatedTimes',
    'AuthorWorks',
    'CommitteeOpinions',
    'Version',
    'CommitteeRevertReasons',
    'ArticleGroups',
    'Committee',
    'Committees',
    'CommitteesByCategory',
    'CurrentUser',
    'ArticleOffers',
    'ArticleOfferDetail',
    'ArticleContentSend',
    'Contract',
    'ArticleOrderMeet',
    'CommitteeGiveOpinion',
    'UserList',
    'AskedCommittees',
    'ArticleChains',
    'ArticleTypes',
    'CommitteeCategories',
    'Author',
    'UserAutoComplete',
    'ArticleOfferGuides',
    'ArticleOffersProcess',
    'ArticleOfferContentTemplate',
    'ActivitiesList',
    'Medias',
    'Guides',
    'Hash',
    'DisposalContracts',
  ],
});

export default apiInstance;
