import { useState, useEffect, useCallback } from 'react';

import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { passwordResetValidateToken } from 'fetcher';
import { fetchError, fetchStart, fetchSuccess } from 'slices/commonSlice';

export const useResetPasswordValidateToken = ({ token = null, hasToken }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isValidate, setIsValidate] = useState(false);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);

  const checkToken = useCallback(
    async (token) => {
      setLoading(true);
      dispatch(fetchStart());
      const data = { token };
      const res = await passwordResetValidateToken(data);

      if (res >= 400) {
        dispatch(fetchError(<FormattedMessage id="app.userAuth.PassResetTokenTimeoutError" />));

        setLoading(false);
        setTimeout(() => {
          navigate('/');
        }, 4000);
      } else {
        dispatch(fetchSuccess());
        setLoading(false);
        setIsValidate(true);
        setUser(res.user);
      }
    },
    [dispatch, navigate]
  );

  useEffect(() => {
    if (hasToken) {
      checkToken(token);
    }
  }, [token, checkToken, hasToken]);

  return { isValidate, user, loading };
};
