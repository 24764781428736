import { useState } from 'react';

import { Button, Col, Form, Modal, Row, Upload } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { FileOutlined, InboxOutlined } from '@ant-design/icons';
import { chunkUploadMedia } from 'utils';

const accept = ['.pdf', '.docx', '.doc', '.txt'];

const UploadAgreement = ({ offerId, authorId, disabled }) => {
  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);
  const { formatMessage } = useIntl();

  const agreementOne = Form.useWatch('agreementOne', form);

  const onFinish = async (values) => {
    try {
      await chunkUploadMedia('contract', [values.agreementOne.file], {
        articleOffer: offerId,
        author: authorId,
      });
    } catch (error) {
      console.error(error);
    }
    setOpen(false);
  };

  return (
    <>
      <Button
        block
        disabled={disabled}
        onClick={() => setOpen(true)}
        className="modalBtnStyle"
      >
        <FormattedMessage id="appModule.uploadAgreement" />
      </Button>
      <Modal
        open={open}
        onCancel={() => setOpen(false)}
        footer={null}
        bodyStyle={{
          padding: '2rem 5rem',
        }}
        destroyOnClose
      >
        <Form
          id="uploadAgreementForm"
          layout="vertical"
          form={form}
          onFinish={onFinish}
        >
          <Row gutter={[24, 24]}>
            <Col span={24}>
              <h1
                style={{
                  textAlign: 'center',
                  textTransform: 'uppercase',
                }}
              >
                {formatMessage({ id: 'appModule.uploadAgreement' })}
              </h1>
              <Form.Item name="agreementOne">
                <Upload.Dragger
                  multiple={false}
                  maxCount={1}
                  accept={accept.join(',')}
                  beforeUpload={() => false}
                  showUploadList={false}
                >
                  {agreementOne && agreementOne.fileList.length > 0 ? (
                    <div
                      style={{
                        padding: '0 16px',
                        display: 'flex',
                        justifyContent: 'start',
                        alignItems: 'center',
                        height: '100%',
                        width: '100%',
                        fontSize: 32,
                      }}
                    >
                      <FileOutlined />
                      <p> {agreementOne.file.name}</p>
                    </div>
                  ) : (
                    <>
                      <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                      </p>
                      <p className="ant-upload-text">
                        <FormattedMessage id="appModule.uploadAgreement" />
                      </p>
                    </>
                  )}
                </Upload.Dragger>
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <Button
          block
          className="gx-mt-4"
          type="primary"
          form={'uploadAgreementForm'}
          htmlType="submit"
        >
          <FormattedMessage id="appModule.save" />
        </Button>
      </Modal>
    </>
  );
};

export default UploadAgreement;
