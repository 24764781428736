import React, { useEffect, useMemo, useState } from 'react';

import { Button, Collapse, Radio, Form, Input, Row, Col, Select, Card, InputNumber } from 'antd';
import { DownOutlined, PlusOutlined, UpOutlined } from '@ant-design/icons';
import { Link, useNavigate } from 'react-router-dom';
import { useIntl, FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import { useAuthors, useCountries, useLanguages } from 'useSWR';
import { openNotification, yokAkademikRegex, yokAkademikBaseURL } from 'utils';
import { CircularProgress, DatePicker, RemoveButton } from 'components';
import { MaskedInput } from 'antd-mask-input';
import { fetchError } from 'slices/commonSlice';
import {
  useAddAuthorMutation,
  useEditAuthorMutation,
  useGetAcademicTitlesQuery,
  useGetAllProjectQuery,
  useGetAuthorTasksQuery,
  useGetCommitteesQuery,
} from 'services';

import AuthorAddress from './AuthorAddress';
import LanguageField from './LanguageField';
import AuthorResearch from './AuthorResearch';

const { Panel } = Collapse;
const { Option } = Select;
const { RangePicker } = DatePicker;

export default ({ author, editable = true }) => {
  const { formatMessage } = useIntl();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { data: committees, isLoading: committeesLoading } = useGetCommitteesQuery();
  const { countries, isLoading: countriesLoading } = useCountries();
  const { data: authorTasks, isFetching: authorTasksLoading } = useGetAuthorTasksQuery();
  const { languages, isLoading: languagesLoading } = useLanguages();
  const [editAuthor] = useEditAuthorMutation();
  const [addAuthor] = useAddAuthorMutation();
  const { data: projects, isLoading: projectsLoading } = useGetAllProjectQuery();
  const { data: academicTitles, isLoading: academicTitlesLoading } = useGetAcademicTitlesQuery();
  const { data: authors, isLoading: authorsLoading } = useAuthors({
    pagination: false,
  });
  const [loading, setLoading] = useState(false);
  const [taskSlice, setTaskSlice] = useState([]);
  const [activePanels, setActivePanels] = useState(['info', 'contact', 'languages']);
  const [copyAddresState, setCopyAddressState] = useState();

  const tr = useMemo(() => {
    if (!countries || countriesLoading) return;

    return countries.find((c) => c.code === 'TR');
  }, [countries, countriesLoading]);

  const minLenght = form.getFieldValue('countryCode') === '90' ? 15 : 4;
  const maxLenght = form.getFieldValue('countryCode') === '90' ? 15 : 14;
  const phoneLenghtMessage =
    form.getFieldValue('countryCode') === '90' ? formatMessage({ id: 'author.info.phone.tr' }) : formatMessage({ id: 'author.info.phone.other' });

  useEffect(() => {
    setLoading(true);

    if (!author || !countries) {
      setLoading(false);
      return form.setFieldsValue({
        status: true,
        countryCode: tr?.phone,
        author: null,
      });
    }

    const [inst, corr] = author.authorAddresses;
    const institute = {
      institutecountry: inst.country,
      institutecity: inst.city || inst.cityRelation['@id'],
      institutedistrict: inst.district || inst.districtRelation['@id'],
      institutedescription: inst.description,
    };
    const correspondence = {
      correspondencecountry: corr.country,
      correspondencecity: corr.city || corr.cityRelation['@id'],
      correspondencedistrict: corr.district || corr.districtRelation['@id'],
      correspondencedescription: corr.description,
    };

    setTaskSlice(author.authorTasks);

    const tasks = author.authorTasks.map((taskItem) => {
      const { project, otherProject, task, otherTask } = taskItem;
      return {
        project: otherProject ? 'other' : project,
        title: otherTask ? 'other' : task['@id'],
        otherTask,
        otherProject,
        date: [taskItem.startDate && dayjs(taskItem.startDate), taskItem.endDate && dayjs(taskItem.endDate)],
      };
    });

    form.setFieldsValue({
      status: author?.user?.memberships.length > 0,
      committee: author?.user?.memberships.map((c) => c['@id']),
      author: author?.user?.fullName,
      nationalIDNumber: author?.nationalIDNumber,
      passportNumber: author?.passportNumber,
      name: author?.user?.firstName,
      surname: author?.user?.lastName,
      orcid: author?.orcId || undefined,
      institute: author?.institution || undefined,
      academicTitle: author?.otherAcademicTitle ? 'other' : author?.academicTitle,
      yokAcademy: author?.yokAcademy ? yokAkademikBaseURL + author?.yokAcademy : undefined,
      cvUrl: author?.cvUrl,
      authorLanguages: author?.authorLanguages?.map((lang) => ({
        ...lang,
        language: lang?.language?.['@id'],
      })),
      email1: author?.user?.email,
      email2: author?.user?.email2,
      phone: author?.user?.phone,
      countryCode: author?.user?.countryCode,
      otherAcademicTitle: author?.otherAcademicTitle,
      tasks,
      ...institute,
      ...correspondence,
    });

    setLoading(false);
  }, [author, form, committees, tr, countries]);

  const [selectedCountryCode, setSelectedCountryCode] = useState();

  useEffect(() => {
    setSelectedCountryCode(countries);
  }, [form, selectedCountryCode, countries]);

  const handleSelectCountryCode = (value) => {
    form.setFieldsValue({ countryCode: value });
    const countryCode = countries.find((c) => c.countryCode === form.getFieldValue(`countryCode`));
    setSelectedCountryCode(countryCode);
  };

  const onIsCommitteeMemberChange = ({ target }) => {
    if (!target.value) {
      form.setFieldsValue({
        author: null,
        email1: author?.user?.email || '',
        email2: '',
        phone: '',
      });
    } else {
      const committee = committees?.find((c) => c['@id'] === form.getFieldValue('committee'));
      const member = committee?.members?.find((m) => m['@id'] === form.getFieldValue('author'));
      form.setFieldsValue({
        email1: member?.user?.email || '',
      });
    }
  };

  const onAuthorChange = (value) => {
    const committee = committees?.find((c) => c['@id'] === form.getFieldValue('committee'));
    const member = committee.members.find((m) => m.user['@id'] === value);
    form.setFieldsValue({ email1: member.user.email });
  };

  const copyAddress = (from) => {
    const fields = ['country', 'city', 'district', 'description'];
    const formFields = form.getFieldsValue(fields.map((f) => from + f));
    const to = from === 'institute' ? 'correspondence' : 'institute';

    const newValues = Object.fromEntries(Object.entries(formFields).map(([k, v]) => [k.replace(from, to), v]));
    setCopyAddressState(newValues);
    form.setFieldsValue(newValues);
  };

  /**
   * Madde yazarı tabında zorunlu olup hata veren alanlar varsa
   * ilgili alanların panellerini aç
   */
  const onError = ({ errorFields }) => {
    const infoFields = ['name', 'surname', 'nationalIDNumber', 'committee', 'author', 'tasks', 'otherAcademicTitle'];
    const contactFields = ['email1', 'phone'];
    const panels = [];

    if (errorFields.some((e) => infoFields.includes(e.name[0]))) {
      panels.push('info');
    }

    if (errorFields.some((e) => contactFields.includes(e.name[0]))) {
      panels.push('contact');
    }

    if (errorFields.some((e) => 'authorLanguages' === e.name[0])) {
      panels.push('languages');
    }

    setActivePanels(panels);
  };

  const onFinish = async (values) => {
    setLoading(true);

    const authorResearchSubjects = values?.authorResearchSubjects?.map((item) => item.label);

    const addressKeys = ['institute', 'correspondence'];
    let authorAddresses = addressKeys.map((key) => {
      // form verilerinden `key` ile başlayan fieldları al
      const fields = Object.entries(values).filter(([k]) => k.startsWith(key));

      // `key` kısmını kaldır, aynı değerler ile yeni obje yarat
      const entries = fields.map(([k, v]) => {
        const address = k.replace(key, '');
        if (address !== 'country' && v?.startsWith('/api')) {
          return [`${address}Relation`, v];
        }
        return [address, v];
      });
      return Object.fromEntries(entries);
    });

    if (author) {
      authorAddresses = authorAddresses.map((address, idx) => ({
        ...address,
        country: countries?.find((c) => c['@id'] === address.country)['@id'],
        id: author.authorAddresses[idx]['@id'],
      }));
    }

    const user = {
      firstName: values.name,
      lastName: values.surname,
      email: values.email1,
      email2: values.email2 || null,
      phone: values.phone.replace(/[ ()]/g, ''),
      countryCode: values.countryCode,
      id: values.author,
    };
    const yokAkademik = new URLSearchParams(values.yokAcademy);

    const tasks = values?.tasks?.map((task, idx) => {
      const { project, otherProject, title, otherTask } = task;
      const authorTask = taskSlice[idx];

      const data = {
        project: otherProject ? null : project,
        task: otherTask ? null : title,
        ...(authorTask && { id: authorTask['@id'] }),
        ...(otherProject && { otherProject }),
        ...(otherTask && { otherTask }),
      };

      if (task.date) {
        const [start, end] = task.date;

        if (start) {
          data.startDate = dayjs(start).format('DD.MM.YYYY');
        }
        if (end) {
          data.endDate = dayjs(end).format('DD.MM.YYYY');
        }
      }

      return data;
    });

    const languages = values?.authorLanguages?.map((lang) => ({
      ...lang,
      reading: lang.reading || 1,
      writing: lang.writing || 1,
      speaking: lang.speaking || 1,
    }));

    const academicTitle = values.otherAcademicTitle ? null : values.academicTitle || undefined;

    const data = {
      academicTitle,
      authorAddresses,
      authorResearchSubjects,
      yokAcademy: yokAkademik.get('authorId') || undefined,
      cvUrl: values.cvUrl || undefined,
      orcId: values.orcid,
      institution: values.institute,
      authorTasks: tasks,
      nationalIDNumber: values.nationalIDNumber ? String(values.nationalIDNumber) : null,
      passportNumber: values.passportNumber || null,
      authorLanguages: languages,
      ...(values.otherAcademicTitle && {
        otherAcademicTitle: values.otherAcademicTitle,
      }),
    };
    if (!author) {
      if (values.author) {
        data.committee = values.committee;
        data.user = { ...user };
      } else {
        data.user = {
          ...user,
        };
      }
    } else {
      data.user = {
        ...user,
        id: author.user['@id'],
      };
    }
    try {
      const { data: resData, error } = await (author ? editAuthor({ id: author.id, body: data }) : addAuthor(data));

      if (error) {
        setLoading(false);
        return dispatch(fetchError(formatMessage({ id: 'article.form.fetchError' }, { error: error.status })));
      }

      if (!author) {
        openNotification({
          message: formatMessage({ id: 'author.redirectMessage' }),
        });
      }
      openNotification({
        message: formatMessage({ id: 'appModule.actionSuccessfully' }),
      });
      navigate(`/yazar-islemleri/${author ? 'yazar-listesi' : `yazar-duzenle/${resData.id}`}`);
    } catch (error) {
      dispatch(fetchError(formatMessage({ id: 'article.form.fetchError' }, { error: error.status })));
      setLoading(false);
    }
  };
  if (
    loading ||
    committeesLoading ||
    authorsLoading ||
    countriesLoading ||
    academicTitlesLoading ||
    authorTasksLoading ||
    languagesLoading ||
    projectsLoading
  ) {
    return <CircularProgress />;
  }

  return (
    <Form
      layout="vertical"
      form={form}
      onFinishFailed={onError}
      onFinish={onFinish}
      scrollToFirstError
    >
      <Collapse
        expandIconPosition="right"
        bordered={false}
        defaultActiveKey={['info']}
        activeKey={activePanels}
        onChange={(key) => setActivePanels(key)}
      >
        <Panel
          header={
            <h4 className="gx-m-0">
              <FormattedMessage id="author.info.title" />
            </h4>
          }
          key="info"
          className="gx-mb-3"
        >
          <Form.Item
            name="status"
            label={<FormattedMessage id="author.info.isCommitteeMember" />}
            className="gx-my-2"
          >
            <Radio.Group
              buttonStyle="solid"
              onChange={onIsCommitteeMemberChange}
              disabled={!editable || author}
            >
              <Radio.Button value={true}>
                <FormattedMessage id="appModule.yes" />
              </Radio.Button>
              <Radio.Button value={false}>
                <FormattedMessage id="appModule.no" />
              </Radio.Button>
            </Radio.Group>
          </Form.Item>
          <Form.Item dependencies={['status']}>
            {({ getFieldValue }) =>
              getFieldValue('status') === false ? (
                <>
                  <Form.Item
                    name="name"
                    label={<FormattedMessage id="appModule.name" />}
                    className="gx-mb-2"
                    rules={[
                      {
                        required: true,
                        message: formatMessage({ id: 'appModule.requiredFieldMessage' }, { field: formatMessage({ id: 'appModule.name' }) }),
                      },
                    ]}
                  >
                    <Input disabled={!editable || author} />
                  </Form.Item>

                  <Form.Item
                    name="surname"
                    label={<FormattedMessage id="appModule.surname" />}
                    className="gx-mb-2"
                    rules={[
                      {
                        required: true,
                        message: formatMessage({ id: 'appModule.requiredFieldMessage' }, { field: formatMessage({ id: 'appModule.surname' }) }),
                      },
                    ]}
                  >
                    <Input disabled={!editable || author} />
                  </Form.Item>
                </>
              ) : (
                <>
                  <Form.Item
                    name="committee"
                    label={<FormattedMessage id="author.info.selectCommittee" />}
                    className="gx-mb-2"
                    rules={[
                      {
                        required: true,
                        message: formatMessage(
                          { id: 'appModule.requiredFieldMessage' },
                          {
                            field: formatMessage({
                              id: 'author.info.selectCommittee',
                            }),
                          }
                        ),
                      },
                    ]}
                  >
                    <Select
                      mode={author && 'multiple'}
                      loading={committeesLoading}
                      disabled={!editable || author}
                      getPopupContainer={(triggerNode) => triggerNode.parentElement}
                    >
                      {author
                        ? author?.user?.memberships?.map((c) => (
                            <Option
                              key={c.id}
                              value={c['@id']}
                            >
                              {c.committee.name}
                            </Option>
                          ))
                        : committees?.map((c) => (
                            <Option
                              key={c.id}
                              value={c['@id']}
                            >
                              {c.name}
                            </Option>
                          ))}
                    </Select>
                  </Form.Item>

                  <Form.Item dependencies={['committee']}>
                    {({ getFieldValue }) => {
                      const committee = committees?.find((c) => c['@id'] === getFieldValue('committee'));

                      return (
                        <Form.Item
                          name="author"
                          label={<FormattedMessage id="author.info.selectAuthor" />}
                          className="gx-mb-2"
                          rules={[
                            {
                              required: true,
                              message: formatMessage(
                                { id: 'appModule.requiredFieldMessage' },
                                {
                                  field: formatMessage({
                                    id: 'author.info.selectAuthor',
                                  }),
                                }
                              ),
                            },
                          ]}
                        >
                          <Select
                            onChange={onAuthorChange}
                            disabled={!editable || author}
                            getPopupContainer={(triggerNode) => triggerNode.parentElement}
                          >
                            {committee?.members?.map(({ user }) => (
                              // <Option
                              //   key={user.id}
                              //   value={user['@id']}
                              //   disabled={authors['hydra:member'].find(
                              //     (a) => a.user && a.user['@id'] === user['@id']
                              //   )}
                              // >
                              //   {user.fullName}
                              // </Option>
                              <Option
                                key={user.id}
                                value={user['@id']}
                                disabled={authors['hydra:member'].find((a) => a.user && a.user['@id'] === user['@id'])}
                              >
                                {user.fullName}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      );
                    }}
                  </Form.Item>
                </>
              )
            }
          </Form.Item>
          <Row
            gutter={24}
            style={{
              flexDirection: 'row',
            }}
          >
            <Col
              sm={24}
              md={12}
            >
              <Form.Item
                name="nationalIDNumber"
                rules={[
                  {
                    validator: (_, value) => {
                      if (value && String(value).length < 11) {
                        return Promise.reject(formatMessage({ id: 'author.info.nationalIDNumberMin' }));
                      } else if (value && String(value).length > 11) {
                        return Promise.reject(formatMessage({ id: 'author.info.nationalIDNumberMax' }));
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
                label={<FormattedMessage id="appModule.nationalIDNumber" />}
                className="gx-mb-2"
                normalize={(value) => (String(value).trim() === '' ? undefined : value)}
              >
                <InputNumber
                  className="gx-w-100"
                  disabled={!editable}
                  controls={false}
                  type="number"
                />
              </Form.Item>
            </Col>
            <Col
              sm={24}
              md={12}
            >
              <Form.Item
                name="passportNumber"
                label={<FormattedMessage id="appModule.passportNumber" />}
                className="gx-mb-2"
                rules={[
                  {
                    min: 5,
                    message: formatMessage({ id: 'author.info.passportNumberMin' }),
                  },
                  {
                    max: 20,
                    message: formatMessage({ id: 'author.info.passportNumberMax' }),
                  },
                ]}
              >
                <Input
                  className="gx-w-100"
                  disabled={!editable}
                />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            name="institute"
            className="gx-mb-2"
            label={<FormattedMessage id="author.info.institute" />}
          >
            <Input disabled={!editable} />
          </Form.Item>

          <Form.List name="tasks">
            {(fields, { add, remove }) => (
              <Form.Item
                label={
                  <>
                    <FormattedMessage id="author.info.tasks" />
                    <Button
                      size="small"
                      type="dashed"
                      icon={<PlusOutlined />}
                      onClick={() => add()}
                      disabled={!editable}
                      className="gx-m-0 gx-ml-2"
                    />
                  </>
                }
              >
                {fields.map(({ key, name, ...rest }) => (
                  <Card
                    key={key}
                    className="gx-mb-3"
                  >
                    <RemoveButton
                      disabled={!editable}
                      onClick={() => taskSlice.splice(name, 1) && remove(name)}
                    />

                    <Form.Item
                      {...rest}
                      name={[name, 'project']}
                      label={<FormattedMessage id="author.info.tasks.project" />}
                      rules={[{ required: true }]}
                      className="gx-mb-2"
                    >
                      <Select
                        disabled={!editable}
                        placeholder={formatMessage({
                          id: 'author.info.tasks.projectPlaceholder',
                        })}
                        getPopupContainer={(triggerNode) => triggerNode.parentElement}
                        options={[
                          ...projects?.map((project) => ({
                            label: project.name,
                            value: project['@id'],
                          })),
                          { label: <FormattedMessage id="appModule.other" />, value: 'other' },
                        ]}
                      />
                    </Form.Item>

                    <Form.Item shouldUpdate>
                      {({ getFieldValue }) => {
                        const task = getFieldValue('tasks')[name];
                        return (
                          task?.project === 'other' && (
                            <Form.Item
                              name={[name, 'otherProject']}
                              className="gx-mb-2"
                              rules={[
                                {
                                  required: true,
                                  message: formatMessage(
                                    { id: 'appModule.requiredFieldMessage' },
                                    {
                                      field: formatMessage({
                                        id: 'author.info.tasks.project',
                                      }),
                                    }
                                  ),
                                },
                              ]}
                            >
                              <Input
                                disabled={!editable}
                                placeholder={formatMessage({
                                  id: 'author.info.tasks.projectOtherPlaceholder',
                                })}
                              />
                            </Form.Item>
                          )
                        );
                      }}
                    </Form.Item>

                    <Form.Item
                      {...rest}
                      name={[name, 'title']}
                      label={<FormattedMessage id="author.info.tasks.title" />}
                      rules={[{ required: true }]}
                      className="gx-mb-2"
                    >
                      <Select
                        disabled={!editable}
                        placeholder={formatMessage({
                          id: 'author.info.tasks.titlePlaceholder',
                        })}
                        getPopupContainer={(triggerNode) => triggerNode.parentElement}
                        options={[
                          ...authorTasks?.map((task) => ({
                            label: task.name,
                            value: task['@id'],
                          })),
                          {
                            label: <FormattedMessage id="appModule.other" />,
                            value: 'other',
                          },
                        ]}
                      />
                    </Form.Item>

                    <Form.Item shouldUpdate>
                      {({ getFieldValue }) => {
                        const task = getFieldValue('tasks')[name];
                        return (
                          task?.title === 'other' && (
                            <Form.Item
                              name={[name, 'otherTask']}
                              className="gx-mb-2"
                              rules={[
                                {
                                  required: true,
                                  message: formatMessage(
                                    { id: 'appModule.requiredFieldMessage' },
                                    {
                                      field: formatMessage({
                                        id: 'author.info.tasks.title',
                                      }),
                                    }
                                  ),
                                },
                              ]}
                            >
                              <Input
                                disabled={!editable}
                                placeholder={formatMessage({
                                  id: 'author.info.tasks.titleOtherPlaceholder',
                                })}
                              />
                            </Form.Item>
                          )
                        );
                      }}
                    </Form.Item>

                    <Form.Item
                      name={[name, 'date']}
                      label={<FormattedMessage id="article.filter.dateRange" />}
                    >
                      <RangePicker
                        locale={dayjs.locale('tr')}
                        format="DD/MM/YYYY"
                        className="gx-w-100"
                        allowEmpty={[true, true]}
                      />
                    </Form.Item>
                  </Card>
                ))}
              </Form.Item>
            )}
          </Form.List>

          <Form.Item
            name="academicTitle"
            className="gx-mb-2"
            label={<FormattedMessage id="author.info.academicTitle" />}
          >
            <Select
              placeholder={<FormattedMessage id="author.info.academicTitlePlaceholder" />}
              getPopupContainer={(triggerNode) => triggerNode.parentElement}
              disabled={!editable}
              options={[
                ...academicTitles?.map((title) => ({
                  label: title.name,
                  value: title['@id'],
                })),
                { label: <FormattedMessage id="appModule.other" />, value: 'other' },
              ]}
              allowClear
            />
          </Form.Item>

          <Form.Item dependencies={['academicTitle']}>
            {({ getFieldValue }) =>
              getFieldValue('academicTitle') === 'other' && (
                <Form.Item
                  name="otherAcademicTitle"
                  className="gx-mb-2"
                  rules={[
                    {
                      required: true,
                      message: formatMessage(
                        { id: 'appModule.requiredFieldMessage' },
                        {
                          field: formatMessage({
                            id: 'author.info.academicTitle',
                          }),
                        }
                      ),
                    },
                  ]}
                >
                  <Input
                    placeholder={formatMessage({
                      id: 'author.info.otherAcademicTitle',
                    })}
                    disabled={!editable}
                  />
                </Form.Item>
              )
            }
          </Form.Item>

          <Form.Item
            name="authorResearchSubjects"
            className="gx-mb-2"
            label={<FormattedMessage id="author.info.research" />}
            help={<FormattedMessage id="author.info.researchHelp" />}
          >
            <AuthorResearch
              form={form}
              author={author}
            />
          </Form.Item>

          <Form.Item
            name="orcid"
            label="ORCID"
            className="gx-mb-2"
          >
            <Input disabled={!editable} />
          </Form.Item>

          <Form.Item
            name="yokAcademy"
            label={<FormattedMessage id="author.info.yokAkademik" />}
            className="gx-mb-2"
            rules={[
              {
                pattern: yokAkademikRegex,
                message: <FormattedMessage id="author.info.yokAkademikError" />,
              },
            ]}
          >
            <Input disabled={!editable} />
          </Form.Item>

          {author?.yokAcademy && (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={yokAkademikBaseURL + author.yokAcademy}
            >
              <FormattedMessage id="author.info.yokAkademikLinkMessage" />
            </a>
          )}

          <Form.Item
            name="cvUrl"
            label={<FormattedMessage id="author.info.cvUrl" />}
            className="gx-mb-2"
          >
            <Input disabled={!editable} />
          </Form.Item>

          {author?.cvUrl && (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={author.cvUrl}
            >
              <FormattedMessage id="author.info.cvUrlLinkMessage" />
            </a>
          )}
        </Panel>
      </Collapse>

      <Collapse
        expandIconPosition="right"
        bordered={false}
        defaultActiveKey={['languages']}
        activeKey={activePanels}
        onChange={(key) => setActivePanels(key)}
      >
        <Panel
          header={
            <h4 className="gx-m-0">
              <FormattedMessage id="author.languages" />
            </h4>
          }
          key="languages"
          className="gx-mb-3"
        >
          <Form.List name="authorLanguages">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name }) => (
                  <Card
                    key={key}
                    className="gx-my-2"
                  >
                    <RemoveButton
                      disabled={!editable}
                      onClick={() => remove(name)}
                    />

                    <Row className="gx-flex-row gx-w-100">
                      <Col span={6}>
                        <Form.Item
                          name={[name, 'language']}
                          label={formatMessage({
                            id: 'author.languages.language',
                          })}
                          rules={[{ required: true }]}
                        >
                          <Select
                            disabled={!editable}
                            getPopupContainer={(triggerNode) => triggerNode.parentElement}
                          >
                            {languages.map((language, i) => (
                              <Option
                                key={i}
                                value={language['@id']}
                              >
                                {language.name}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <LanguageField
                          editable={editable}
                          name={[name, 'reading']}
                          title={<FormattedMessage id="author.languages.reading" />}
                        />
                      </Col>
                      <Col span={6}>
                        <LanguageField
                          editable={editable}
                          name={[name, 'writing']}
                          title={<FormattedMessage id="author.languages.writing" />}
                        />
                      </Col>
                      <Col span={6}>
                        <LanguageField
                          editable={editable}
                          name={[name, 'speaking']}
                          title={<FormattedMessage id="author.languages.speaking" />}
                        />
                      </Col>
                    </Row>
                  </Card>
                ))}

                <Button
                  type="dashed"
                  onClick={() => add()}
                  icon={<PlusOutlined />}
                  disabled={!editable}
                  className="gx-m-0"
                  block
                >
                  {' '}
                  <FormattedMessage id="author.languages.new" />
                </Button>
              </>
            )}
          </Form.List>
        </Panel>
      </Collapse>

      <Collapse
        expandIconPosition="right"
        bordered={false}
        defaultActiveKey={['contact']}
        activeKey={activePanels}
        onChange={(key) => setActivePanels(key)}
      >
        <Panel
          header={
            <h4 className="gx-m-0">
              <FormattedMessage id="author.info.contactInfo" />
            </h4>
          }
          key="contact"
          className="gx-mb-3"
        >
          <Form.Item dependencies={['status', 'author']}>
            {({ getFieldValue }) => (
              <Form.Item
                name="email1"
                label={
                  <FormattedMessage
                    id="author.info.nthEmail"
                    values={{ n: 1 }}
                  />
                }
                className="gx-mb-2"
                rules={[
                  {
                    required: true,
                    message: formatMessage(
                      { id: 'appModule.requiredFieldMessage' },
                      {
                        field: formatMessage({ id: 'author.info.nthEmail' }, { n: 1 }),
                      }
                    ),
                  },
                ]}
              >
                <Input disabled={!editable || getFieldValue('status') === true || getFieldValue('author')} />
              </Form.Item>
            )}
          </Form.Item>

          <Form.Item
            name="email2"
            label={
              <FormattedMessage
                id="author.info.nthEmail"
                values={{ n: 2 }}
              />
            }
            className="gx-mb-2"
          >
            <Input disabled={!editable} />
          </Form.Item>

          <Form.Item
            label={<FormattedMessage id="author.info.phone" />}
            className="gx-mb-2"
            required
          >
            <Input.Group>
              <Row
                className="gx-flex-row"
                gutter={8}
              >
                <Col span={2}>
                  <Form.Item name="countryCode">
                    <Select
                      showSearch
                      dropdownMatchSelectWidth={false}
                      optionLabelProp="label"
                      disabled={!editable}
                      filterOption={(input, option) => option.title.toLowerCase().includes(input.toLowerCase())}
                      getPopupContainer={(triggerNode) => triggerNode.parentElement}
                      style={{ width: '100%' }}
                      onChange={handleSelectCountryCode}
                    >
                      {selectedCountryCode?.map((c) => (
                        <Option
                          key={c.code}
                          title={c.name}
                          label={`${c.code} +${c.phone}`}
                          value={c.phone}
                        >
                          {c.name} <small>+{c.phone}</small>
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={22}>
                  {form.getFieldValue('countryCode') !== '90' ? (
                    <Form.Item
                      name="phone"
                      rules={[
                        {
                          required: true,
                          message: formatMessage({ id: 'appModule.requiredFieldMessage' }),
                        },
                        {
                          min: minLenght,
                          message: phoneLenghtMessage,
                        },
                        {
                          max: maxLenght,
                          message: phoneLenghtMessage,
                        },
                        {
                          pattern: new RegExp('^[0-9]*$'),
                          message: formatMessage({ id: 'author.info.phone.onlyNumber' }),
                        },
                      ]}
                    >
                      <Input
                        placeholder={formatMessage({ id: 'author.info.phone' })}
                        disabled={!editable}
                        type="text"
                      />
                    </Form.Item>
                  ) : (
                    <Form.Item
                      name="phone"
                      rules={[
                        {
                          required: true,
                          message: formatMessage({ id: 'appModule.requiredFieldMessage' }),
                        },
                        {
                          min: author ? 10 : minLenght,
                          message: phoneLenghtMessage,
                        },
                        {
                          max: maxLenght,
                          message: phoneLenghtMessage,
                        },
                      ]}
                    >
                      <MaskedInput
                        mask={'(000) 000 00 00'}
                        placeholder={formatMessage({ id: 'author.info.phone' })}
                        disabled={!editable}
                        type="text"
                      />
                    </Form.Item>
                  )}
                </Col>
              </Row>
            </Input.Group>
          </Form.Item>
        </Panel>
      </Collapse>

      <AuthorAddress
        copyAddress={copyAddresState}
        title="Kurum Adresi"
        prefix="institute"
        editable={editable}
      />

      <div className="gx-d-flex">
        <Button
          block
          onClick={() => copyAddress('institute')}
        >
          <DownOutlined className="gx-mr-2" />
          <FormattedMessage id="author.info.copyFromInstitute" />
        </Button>
        <Button
          block
          onClick={() => copyAddress('correspondence')}
        >
          <UpOutlined className="gx-mr-2" />
          <FormattedMessage id="author.info.copyFromCorrespondence" />
        </Button>
      </div>

      <AuthorAddress
        title="Yazışma Adresi"
        prefix="correspondence"
        editable={editable}
      />

      {editable && (
        <div className="gx-d-flex gx-justify-content-end">
          <Link to="/yazar-islemleri/yazar-listesi">
            <Button className="gx-mr-3">
              <FormattedMessage id="appModule.cancel" />
            </Button>
          </Link>
          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
          >
            <FormattedMessage id="appModule.save" />
          </Button>
        </div>
      )}
    </Form>
  );
};
