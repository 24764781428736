import ArticleSearchEdisonOne from 'module/Select/ArticleSearchEdisonOne';

import React, { useEffect } from 'react';

import { Card, Checkbox, Col, InputNumber, Form, Radio, Row, Switch } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { ArticleLengthRadioGroup } from 'components/RadioGroup';

const deadline = {
  0: 45,
  1: 100,
  2: 160,
  3: 260,
  4: 300,
};

const updateWorkTypes = {
  tashih: 3,
  ikmal: 1,
  kapsamliGozdenGecirme: 2,
  yeniBolumIlavesi: 7,
  gorselGuncelleme: 6,
};

const DetectionDetail = ({ isDisabled = false }) => {
  const { formatMessage } = useIntl();
  const form = Form.useFormInstance();
  // bu alanlar formun verilerindeki değişiklikleri anlık olarak izlemek için kullanılıyor
  const existinFirstEdition = Form.useWatch('birinciEdisyondaYerAldi', form);
  const atifTam = Form.useWatch('atifTam', form);
  const closeExpressionExist = Form.useWatch('yakinIfadeZikrediliyorMu', form);
  const articleGroup = Form.useWatch('articleGroup', form);
  const oldArticleWorkType = Form.useWatch('oldArticleWorkType', form);
  const rewriteandUpdate = Form.useWatch('rewriteandUpdate', form);
  const articleLength = Form.useWatch('articleLength', form);
  const switchValue = Form.useWatch('switch', form);

  useEffect(() => {
    if (articleGroup?.includes('2') && articleLength !== 0) {
      form.setFieldsValue({ articleLength: 0 });
    } else if (!articleGroup?.includes('2') && articleLength === 0) {
      form.setFieldsValue({ articleLength: 1 });
    }
  }, [articleGroup, articleLength, form]);

  useEffect(() => {
    if (switchValue === false) {
      form.setFieldsValue({ articleLengthDay: null });
    }
  }, [form, switchValue]);

  useEffect(() => {
    if (articleLength !== undefined && articleLength !== 4) form.setFieldsValue({ articleLengthOther: null });
  }, [articleLength, form]);

  useEffect(() => {
    if (rewriteandUpdate === 'Yeniden Yazım') {
      return form.setFieldsValue({ oldArticleWorkType: 0 });
    }
  }, [rewriteandUpdate, form]);

  return (
    <>
      <Card
        style={{ marginBottom: 12, border: 0 }}
        hoverable
      >
        <Form.Item
          name="birinciEdisyondaYerAldi"
          labelfor={'birinciEdisyondaYerAldi'}
          label={<FormattedMessage id="article.form.articleBeenEdisionOne" />}
          rules={[
            {
              required: true,
              message: formatMessage({
                id: 'appModule.requiredMessage',
              }),
            },
          ]}
        >
          <Radio.Group
            name="birinciEdisyondaYerAldi"
            buttonStyle="solid"
          >
            <Radio.Button
              className="gx-mt-2"
              value="Evet"
            >
              <FormattedMessage id="appModule.yes" />
            </Radio.Button>
            <Radio.Button
              className="gx-mt-2"
              value="Hayır"
            >
              <FormattedMessage id="appModule.no" />
            </Radio.Button>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          dependencies={['birinciEdisyondaYerAldi']}
          style={{
            cursor: 'default',
          }}
        >
          {() => {
            if (existinFirstEdition === 'Evet') {
              return (
                <div className="gx-mb-3 gx-ml-5">
                  <Form.Item
                    label={<FormattedMessage id="article.form.atifTam" />}
                    labelfor="atifTam"
                    name="atifTam"
                    dependencies={['birinciEdisyondaYerAldi']}
                    rules={[
                      {
                        required: true,
                        message: formatMessage({
                          id: 'appModule.requiredMessage',
                        }),
                      },
                    ]}
                  >
                    <Radio.Group
                      onChange={() =>
                        form.setFieldsValue({
                          oldTitle: null,
                          oldArticleWorkType: null,
                          rewriteandUpdate: null,
                        })
                      }
                      buttonStyle="solid"
                    >
                      <Radio.Button value="Tam">
                        <FormattedMessage id="article.form.Tam" />
                      </Radio.Button>
                      <Radio.Button value="Atıf">
                        <FormattedMessage id="article.form.Atif" />
                      </Radio.Button>
                    </Radio.Group>
                  </Form.Item>
                </div>
              );
            }
          }}
        </Form.Item>
        <Form.Item dependencies={['atifTam', 'birinciEdisyondaYerAldi']}>
          {() => {
            if (atifTam && existinFirstEdition === 'Evet') {
              return (
                <div className="gx-mb-3 gx-ml-5">
                  <Form.Item
                    label={<FormattedMessage id="article.form.articleHasBeenIslanEnCyclopedia" />}
                    labelfor="oldTitle"
                    name="oldTitle"
                    rules={[
                      {
                        required: true,
                        message: formatMessage({
                          id: 'appModule.requiredMessage',
                        }),
                      },
                    ]}
                  >
                    <ArticleSearchEdisonOne />
                  </Form.Item>
                  <Form.Item
                    dependencies={['atifTam', 'birinciEdisyondaYerAldi', 'rewriteandUpdate']}
                    className="gx-mt-2"
                  >
                    {() => {
                      if (existinFirstEdition === 'Evet' && atifTam === 'Tam')
                        return (
                          <div>
                            <Form.Item
                              label={<FormattedMessage id="article.form.oldArticleWorkType" />}
                              labelfor="rewriteandUpdate"
                              name="rewriteandUpdate"
                              dependencies={['birinciEdisyondaYerAldi']}
                              rules={[
                                {
                                  required: true,
                                  message: formatMessage({
                                    id: 'appModule.requiredMessage',
                                  }),
                                },
                              ]}
                            >
                              <Radio.Group buttonStyle="solid">
                                <Radio.Button value="Yeniden Yazım">
                                  <FormattedMessage id="article.formWorkToBeRewrite" />
                                </Radio.Button>
                                <Radio.Button value="Güncelleme">
                                  <FormattedMessage id="article.form.Update" />
                                </Radio.Button>
                              </Radio.Group>
                            </Form.Item>
                          </div>
                        );
                    }}
                  </Form.Item>
                  <Form.Item
                    shouldUpdate
                    dependencies={['rewriteandUpdate', 'oldArticleWorkType']}
                  >
                    {() => {
                      const isHaveZero = Array.isArray(oldArticleWorkType) && oldArticleWorkType?.includes(2);
                      const isNewPartAdded = Array.isArray(oldArticleWorkType) && oldArticleWorkType.includes(7);
                      if (rewriteandUpdate === 'Güncelleme' && atifTam === 'Tam') {
                        return (
                          <Form.Item
                            name="oldArticleWorkType"
                            labelfor="oldArticleWorkType"
                            rules={[
                              {
                                required: true,
                                message: formatMessage({
                                  id: 'appModule.requiredMessage',
                                }),
                              },
                            ]}
                          >
                            {atifTam === 'Tam' && (
                              <Checkbox.Group
                                onChange={(values) => {
                                  if (values.includes(updateWorkTypes.yeniBolumIlavesi)) {
                                    const selectedValue = values.filter(
                                      (item) => item === updateWorkTypes.yeniBolumIlavesi || item === updateWorkTypes.gorselGuncelleme
                                    );
                                    form.setFieldsValue({ oldArticleWorkType: selectedValue });
                                  }
                                  if (
                                    values.includes(updateWorkTypes.kapsamliGozdenGecirme) &&
                                    (values.includes(updateWorkTypes.tashih) || values.includes(updateWorkTypes.ikmal))
                                  ) {
                                    const selectedValue = values.filter(
                                      (item) => item === updateWorkTypes.kapsamliGozdenGecirme || item === updateWorkTypes.gorselGuncelleme
                                    );
                                    form.setFieldsValue({ oldArticleWorkType: selectedValue });
                                  }
                                }}
                                disabled={isDisabled}
                              >
                                <Checkbox
                                  className="gx-mt-2"
                                  value={updateWorkTypes.tashih}
                                  disabled={isHaveZero || isNewPartAdded}
                                >
                                  <FormattedMessage id="article.formWorkToBeCorrection" />
                                </Checkbox>
                                <br />
                                <Checkbox
                                  className="gx-mt-2"
                                  value={updateWorkTypes.ikmal}
                                  disabled={isHaveZero || isNewPartAdded}
                                >
                                  <FormattedMessage id="article.formWorkToBeSupply" />
                                </Checkbox>
                                <br />

                                <Checkbox
                                  className="gx-mt-2"
                                  value={updateWorkTypes.kapsamliGozdenGecirme}
                                  disabled={isNewPartAdded}
                                >
                                  <FormattedMessage id="article.formWorkToBeReview" />
                                </Checkbox>
                                <br />
                                <Checkbox
                                  className="gx-mt-2"
                                  value={updateWorkTypes.yeniBolumIlavesi}
                                >
                                  <FormattedMessage id="article.formWorkAddPart" />
                                </Checkbox>
                                <br />
                                <Checkbox
                                  className="gx-mt-2"
                                  value={updateWorkTypes.gorselGuncelleme}
                                  data-cy="oldTitle-work-type-4"
                                >
                                  <FormattedMessage id="article.imageUpdate" />
                                </Checkbox>
                              </Checkbox.Group>
                            )}
                          </Form.Item>
                        );
                      }
                    }}
                  </Form.Item>
                </div>
              );
            }
          }}
        </Form.Item>
      </Card>
      <Card
        style={{ marginBottom: 12, border: 0 }}
        hoverable
      >
        <Form.Item
          name="yakinIfadeZikrediliyorMu"
          labelfor={'yakinIfadeZikrediliyorMu'}
          label={<FormattedMessage id="article.detail.Encyclopedia" />}
          rules={[
            {
              required: true,
              message: formatMessage({
                id: 'appModule.requiredMessage',
              }),
            },
          ]}
        >
          <Radio.Group
            name="yakinIfadeZikrediliyorMu"
            buttonStyle="solid"
          >
            <Radio.Button
              className="gx-mt-2"
              value="Evet"
            >
              <FormattedMessage id="appModule.yes" />
            </Radio.Button>
            <Radio.Button
              className="gx-mt-2"
              value="Hayır"
            >
              <FormattedMessage id="appModule.no" />
            </Radio.Button>
          </Radio.Group>
        </Form.Item>
        <Form.Item dependencies={['yakinIfadeZikrediliyorMu']}>
          {() => {
            if (closeExpressionExist === 'Evet') {
              return (
                <div className="gx-mb-3 gx-ml-5 gx-mt-2">
                  <Form.Item
                    label={<FormattedMessage id="article.form.WhichOldArticles" />}
                    labelfor={'oldArticles'}
                    name={'oldArticles'}
                    rules={[
                      {
                        required: true,
                        message: formatMessage({
                          id: 'appModule.requiredMessage',
                        }),
                      },
                    ]}
                  >
                    <ArticleSearchEdisonOne type={'Tam'} />
                  </Form.Item>
                </div>
              );
            }
          }}
        </Form.Item>
      </Card>
      <Card style={{ marginBottom: 12, border: 0 }}>
        <Row
          gutter={24}
          style={{
            flexDirection: 'row',
          }}
        >
          <Col
            sm={24}
            md={14}
          >
            <div className="articleLength">
              <div>
                <Form.Item
                  name="articleLength"
                  label={formatMessage({ id: 'article.detail.articleLength' })}
                  rules={
                    !articleGroup?.includes('2') && {
                      required: formatMessage(
                        { id: 'appModule.requiredFieldMessage' },
                        {
                          field: formatMessage({
                            id: 'article.detail.articleLength',
                          }),
                        }
                      ),
                    }
                  }
                  required
                >
                  <ArticleLengthRadioGroup
                    isDictionary={articleGroup?.includes('1')}
                    isEncyclopedia={articleGroup?.includes('2')}
                  />
                </Form.Item>
              </div>
              {articleLength === 4 && (
                <div className="articleLengthCustom">
                  <Form.Item
                    rules={[
                      {
                        required: articleLength === 4,
                        message: formatMessage({
                          id: 'appModule.requiredMessage',
                        }),
                      },
                    ]}
                    name="articleLengthCustom"
                  >
                    <InputNumber
                      min={7001}
                      type="number"
                      placeholder="Hacim"
                    />
                  </Form.Item>
                </div>
              )}
            </div>
          </Col>
          <Col
            sm={24}
            md={10}
          >
            <Form.Item
              name="articleLengthDay"
              label={
                <div className="gx-d-flex gx-align-items-center">
                  {formatMessage({ id: 'article.detail.articleLengthDay' })}{' '}
                  <Form.Item
                    valuePropName="checked"
                    name="switch"
                  >
                    <Switch size="small" />
                  </Form.Item>
                </div>
              }
            >
              <InputNumber
                min={0}
                disabled={!switchValue || isDisabled}
                step={1}
                className="gx-w-50"
                placeholder={deadline[articleLength]}
              />
            </Form.Item>
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default DetectionDetail;
