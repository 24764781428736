import { useEffect, useState } from 'react';

import { Col, Row, Tabs } from 'antd';
import { FormattedMessage } from 'react-intl';
import { useCommitteeOpinions, usePullbackRequests } from 'useSWR';
import { DownButton } from 'components';
import { useUserInfo } from 'providers/UserInfoProvider';

import { ArticleInfos, ArticleStatus, ArticleSteps } from './offer';
import { ContributionRequest, RequestList } from './offer/ArticleContribution';
import { PullbackRequests, PullbackResponse } from './offer/ArticlePullback';

const ArticleOfferDetail = ({ offer, process }) => {
  const [activeStep, setActiveStep] = useState('1');
  const { pullbackRequests } = usePullbackRequests({ id: offer?.id, step: 1 });
  const { opinions } = useCommitteeOpinions(offer?.id);
  const { memberships, isAdmin, isDAH, isAYK } = useUserInfo();

  const hasActivePullback = pullbackRequests?.some((req) => req.status === 0);
  const isCommitteeMember = memberships.some((item) => item.committee.id === offer?.ownerCommittee?.id && [0, 1].includes(item.type));
  const opinionsToDecide = opinions?.request?.filter((opinion) => !opinion.opinionDate);

  useEffect(() => {
    if (offer) {
      const { committeeStatus, dahStatus, aykStatus } = offer;
      if ([0, 7].includes(committeeStatus)) {
        return setActiveStep('2');
      }
      if (dahStatus === 0) {
        return setActiveStep('3');
      }
      if (aykStatus === 0) {
        return setActiveStep('4');
      }
    }
  }, [offer]);

  const tabItems = [
    {
      key: '1',
      label: <FormattedMessage id="article.steps.ArticleOffer" />,
      disabled: !offer?.isOffer,
      children: (
        <Row
          justify="center"
          align="middle"
        >
          <Col
            md={24}
            lg={18}
          >
            <ArticleInfos
              offer={offer}
              offerOldArticles={offer.oldArticles}
              situation="MTF"
            />
          </Col>
        </Row>
      ),
    },
    {
      key: '2',
      label: <FormattedMessage id="article.steps.ArticleDetection" />,
      children: (
        <Row className="gx-position-relative">
          <Col
            md={24}
            lg={12}
          >
            <DownButton
              title={
                <FormattedMessage
                  id="article.detail.downButtonDecision"
                  values={{ to: 'Heyet' }}
                />
              }
              section="heyetKarari"
            />
            <ArticleInfos
              offer={offer}
              offerOldArticles={offer.oldArticles}
              situation="committee"
            />
          </Col>

          <Col
            md={24}
            lg={12}
            id="heyetKarari"
          >
            <ArticleStatus
              offer={offer}
              situation="committee"
              hasActivePullback={hasActivePullback}
            />

            {(isCommitteeMember || isAdmin) && (
              <PullbackRequests
                id={offer?.id}
                committee="committee"
                pullbackRequests={pullbackRequests}
              />
            )}

            {offer?.committeeStatus === 2 && opinionsToDecide?.length > 0 && (
              <ContributionRequest
                offer={offer}
                opinions={opinionsToDecide}
              />
            )}

            {opinions?.decision?.length > 0 && <RequestList opinions={opinions?.decision} />}
          </Col>
        </Row>
      ),
    },
    {
      key: '3',
      label: <FormattedMessage id="taek" />,
      disabled: offer?.step < 2 && offer?.status === 19,
      children: (
        <Row>
          <Col
            md={24}
            lg={12}
          >
            <DownButton
              title={
                <FormattedMessage
                  id="article.detail.downButtonDecision"
                  values={{ to: 'TAEK' }}
                />
              }
              section="dahKarari"
            />
            <ArticleInfos
              offer={offer}
              offerOldArticles={offer.oldArticles}
            />
          </Col>

          <Col
            md={24}
            lg={12}
            id="dahKarari"
          >
            <ArticleStatus
              offer={offer}
              situation="dah"
              hasActivePullback={hasActivePullback}
            />

            {(isAdmin || isDAH) && (
              <>
                <PullbackRequests
                  id={offer?.id}
                  committee="dah"
                  pullbackRequests={pullbackRequests}
                />

                <PullbackResponse
                  id={offer?.id}
                  committee="dah"
                  pullbackRequests={pullbackRequests}
                />
              </>
            )}
          </Col>
        </Row>
      ),
    },
    {
      key: '4',
      label: <FormattedMessage id="aykShort" />,
      disabled: offer?.step < 2 && offer?.status === 19,
      children: (
        <Row>
          <Col
            md={24}
            lg={12}
          >
            <DownButton
              title={
                <FormattedMessage
                  id="article.detail.downButtonDecision"
                  values={{ to: 'AYK' }}
                />
              }
              section="aykKarari"
            />
            <ArticleInfos
              offer={offer}
              offerOldArticles={offer.oldArticles}
            />
          </Col>

          <Col
            md={24}
            lg={12}
            id="aykKarari"
          >
            <ArticleStatus
              offer={offer}
              situation="ayk"
              hasActivePullback={hasActivePullback}
            />

            {(isAdmin || isAYK) && (
              <PullbackResponse
                id={offer?.id}
                committee="ayk"
                pullbackRequests={pullbackRequests}
              />
            )}
          </Col>
        </Row>
      ),
    },
  ];

  return (
    <>
      <h2 className="gx-h2-lg">
        <FormattedMessage id="article.detection.title" />
      </h2>

      <Row
        justify="center"
        align="middle"
      >
        <Col
          xl={24}
          xxl={18}
        >
          <ArticleSteps
            process={process}
            step={offer?.step}
            status={offer?.status}
            committeeStatus={offer.committeeStatus}
            dahStatus={offer.dahStatus}
            aykStatus={offer.aykStatus}
          />
        </Col>

        <Col xs={24}>
          <Tabs
            type="card"
            activeKey={activeStep}
            onTabClick={(key) => setActiveStep(key)}
            centered
            animated
            destroyInactiveTabPane
            items={tabItems}
          />
        </Col>
      </Row>
    </>
  );
};

export default ArticleOfferDetail;
