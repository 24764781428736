import UserForm from 'module/Form/UserForm';

import { useState } from 'react';

import { Button, Drawer, Form } from 'antd';
import { userEdit, userRenewPassword } from 'fetcher';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { openNotification } from 'utils';
import { useDispatch } from 'react-redux';
import { fetchError } from 'slices/commonSlice';
import { useUsers } from 'useSWR';
import { CircularProgress } from 'components/CircularProgress';
import { useGetCurrentUserQuery } from 'services';

const EditUserDrawer = ({ id, ...other }) => {
  const [form] = Form.useForm();
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [formLoading, setFormLoading] = useState(false);
  const [showPassCange, setShowPassCange] = useState(false);
  const { users: user, isLoading: usersLoading, mutate } = useUsers({ id });
  const { data: current_user, isLoading } = useGetCurrentUserQuery();

  const newPass = Form.useWatch('newPassword', form);

  if (usersLoading || isLoading) return <CircularProgress />;

  const onFinish = async (values) => {
    setFormLoading(true);
    try {
      await userEdit(id, values);
      openNotification({
        message: formatMessage({ id: 'appModule.actionSuccessfully' }),
      });
      mutate();
      setTimeout(() => {
        setFormLoading(false);
        navigate('/uye-islemleri/uye-listesi');
      }, 1500);
    } catch (error) {
      dispatch(
        fetchError(
          formatMessage(
            {
              id: 'article.form.fetchError',
            },
            { error: `${error}` }
          )
        )
      );
    } finally {
      setFormLoading(false);
      other.onClose();
    }
  };

  const changePassword = async () => {
    const pass = {
      newPassword: newPass,
    };
    setFormLoading(false);

    try {
      await userRenewPassword(id, pass);
      openNotification({
        message: formatMessage({ id: 'appModule.actionSuccessfully' }),
      });
      setTimeout(() => {
        setFormLoading(false);
        setShowPassCange(!showPassCange);
      }, 1500);
    } catch (error) {
      dispatch(
        fetchError(
          formatMessage(
            {
              id: 'article.form.fetchError',
            },
            { error: `${error}` }
          )
        )
      );
    } finally {
      setFormLoading(false);
    }
  };

  return (
    <Drawer
      style={{
        overflow: 'hidden',
      }}
      headerStyle={{ padding: 0 }}
      footerStyle={{
        padding: 0,
      }}
      width={750}
      bodyStyle={{ paddingTop: '1rem', paddingLeft: 0, paddingRight: 0, overflow: 'hidden' }}
      title={<FormattedMessage id="userList.userEdit" />}
      destroyOnClose
      footer={
        <Button
          form="userEditForm"
          block
          type="primary"
          className="gx-mb-0"
          htmlType="submit"
          disabled={formLoading || showPassCange}
        >
          <FormattedMessage id="appModule.save" />
        </Button>
      }
      {...other}
    >
      <Form
        id="userEditForm"
        form={form}
        layout="vertical"
        onFinish={onFinish}
        initialValues={user}
      >
        <UserForm
          vertical
          isAdmin={current_user?.isSuperAdmin}
          hasPassChangePermission={!user?.roles?.includes('ROLE_SUPER_ADMIN')}
          userEdit
          showPassCange={showPassCange}
          handlePassChange={() => setShowPassCange(!showPassCange)}
          changePassword={changePassword}
        />
      </Form>
    </Drawer>
  );
};

export default EditUserDrawer;
