import academicTitleApi from './academicTitle';
import activityApi from './activities';
import articleApi from './articleApi';
import authorArticleApi from './authorArticle';
import authorTaskApi from './authorTask';
import authorWorksApi from './authorWorksApi';
import committeeRevertReasonApi from './commiteeRevertReason';
import committeeOpinionsApi from './committeeOpinionsApi';
import commiteesApi from './committeesApi';
import orderRevertReasonApi from './orderRevertReason';
import projectApi from './projectApi';
import roleApi from './roleApi';
import stiplatedTimeApi from './stipulatedTime';
import versionApi from './versionApi';
import articleOfferApi from './articleOffer';
import orderUnitProcess from './orderUnitProcess';
import authApi from './auth';
import currentUserApi from './currentUser';
import authorOpinionApi from './authorOpinion';
import authorSendContentApi from './authorSendContent';
import contractApi from './contract';
import orderMeetApi from './orderMeets';
import committeControlDecisionApi from './committeControlDecision';
import userApi from './user';
import { authorApi } from './author';
import mediaApi from './media';
import guideApi from './guide';
import hashApi from './hash';
import disposalContractApi from './disposalContract';

export { default as apiInstance } from './api';

export const { useGetAuthorArticlesQuery } = authorArticleApi;
export const { useGetActivitiesQuery, useGetActivitiesListQuery } = activityApi;
export const { useGetAcademicTitlesQuery } = academicTitleApi;
export const { useGetAuthorTasksQuery } = authorTaskApi;
export const { useGetRolesQuery } = roleApi;
export const { useGetStiplatedTimeQuery } = stiplatedTimeApi;
export const { useGetAllProjectQuery } = projectApi;
export const { useGetAllOrderRevertReasonQuery } = orderRevertReasonApi;
export const { useGetAuthorWorksQuery } = authorWorksApi;
export const { useGetCommitteeOpinionsQuery } = committeeOpinionsApi;
export const { useGetVersionQuery } = versionApi;
export const { useGetCommitteeRevertReasonsQuery } = committeeRevertReasonApi;
export const { useGetArticleGroupsQuery, useGetArticleTypesQuery } = articleApi;
export const {
  useGetCommitteeQuery,
  useGetCommitteesQuery,
  useGetCommitteesByCategoryQuery,
  useRemoveFromCommiteeMutation,
  useAddUserToCommitteeMutation,
  useEditCommiteeMutation,
  useCreateCommitteeMutation,
  useGetAllCommitteeCategoriesQuery,
} = commiteesApi;
export const { useUpdateOrderDateMutation, useOrderUnitDecisionMutation } = orderUnitProcess;
export const {
  useGetOfferQuery,
  useSendDecisionMutation,
  useSendArticleProcessMutation,
  useSendCommitteeDecisionMutation,
  useChangeSupervisorMutation,
  useChangeDeadlineMutation,
  useGetArticleChainsQuery,
  useGetArticleOfferGuidesQuery,
  useGetArticleOfferProcessQuery,
  useLazyDownloadArticleOfferContentTemplateQuery,
} = articleOfferApi;
export const { useGetCurrentUserQuery, useUpdateCurrentUserMutation } = currentUserApi;
export const { useGetUserListQuery, useGetFilteredUserListQuery } = userApi;
export const { useLoginMutation } = authApi;
export const {
  useSendArticleContentMutation,
  useGetArticleContentQuery,
  useUpdateArticleContentMutation,
  useDeleteArticleContentMediaMutation,
  useDeleteFormContentMediaMutation,
} = authorSendContentApi;
export const { useSendAuthorOpinionMutation } = authorOpinionApi;
export const { useGetContractQuery } = contractApi;
export const { useSendArticleOrderMeetMutation, useGetArticleOrderMeetQuery } = orderMeetApi;
export const { useSendCommitteeControlDecisionMutation, useGetDecidedCommitteesQuery, useGiveCommitteeOpinionMutation, useGetAskedCommitteesQuery } =
  committeControlDecisionApi;
export const { useGetAuthorQuery, useEditAuthorMutation, useAddAuthorMutation } = authorApi;
export const { useGetMediasQuery, useDeleteMediaMutation } = mediaApi;
export const { useGetGuidesQuery } = guideApi;
export const { useGetHashQuery } = hashApi;
export const {
  useGetDisposalContractsQuery,
  useCalculateDisposalContactPriceMutation,
  useCreateDisposalContractMutation,
  useUpdateDisposalContactConfigMutation,
  useUpdateDisposalContractMutation,
} = disposalContractApi;
