import ArticleDetection from 'module/Form/ArticleDetection';

import React, { useEffect, useState } from 'react';

import { Col, Row } from 'antd';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { useAlertLeavingPage } from 'hooks';
import { editOffer } from 'fetcher';
import { checkFilesSize, OFFER_STATUS, openNotification } from 'utils';
import { CircularProgress, Form, Title } from 'components';
import { fetchError } from 'slices/commonSlice';
import { apiInstance, useGetOfferQuery } from 'services';

const EditArticleForm = () => {
  const { formatMessage } = useIntl();
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const { data: offer, isLoading: offerLoading } = useGetOfferQuery(id);

  //Formdan ayılırken alert veren hook
  useAlertLeavingPage();
  const [fileList, setFileList] = useState([]);

  const [buttonLoading, setButtonLoading] = useState(false);

  const onFinish = async (values) => {
    console.log('values', values.oldArticleWorkType);
    setButtonLoading(true);
    try {
      checkFilesSize(fileList);
      await editOffer(
        id,
        {
          ...values,
          ...(Array.isArray(values.oldArticleWorkType)
            ? { oldArticleWorkType: values.oldArticleWorkType }
            : { oldArticleWorkType: values.rewriteandUpdate === 'Yeniden Yazım' ? [0] : [] }),
          authors: values.authors.map((item) => {
            return {
              id: item.id,
              author: item.author,
              reason: item.reason,
            };
          }),
          oldTitle: values.oldTitle?.value ?? null,
          oldArticles: values.oldArticles?.map((item) => item.value) ?? [],
        },
        fileList
      );

      openNotification({
        message: formatMessage({
          id: 'appModule.actionSuccessfully',
        }),
      });
      dispatch(apiInstance.util.invalidateTags(['ArticleOffers', 'ArticleOfferDetail', 'AuthorArticles']));
      navigate(`/madde-detay/${id}`);
    } catch (error) {
      dispatch(
        fetchError(
          formatMessage(
            {
              id: 'article.form.fetchError',
            },
            { error: `${error}` }
          )
        )
      );
      setButtonLoading(false);
    } finally {
      setButtonLoading(false);
    }
  };

  useEffect(() => {
    if (offer?.media?.length > 0) {
      const files = offer.media.map(({ media }) => ({
        uid: media.id,
        name: media?.originalFilename,
        size: media?.filesize,
        status: 'done',
      }));
      setFileList(files);
    }
  }, [offer]);

  if (id && offerLoading) return <CircularProgress />;

  return (
    <>
      <Title>{offer.title + ' - ' + formatMessage({ id: 'article.form.detectionFormTitleEdit' })}</Title>
      <h1 className="gx-h1-lg">
        <FormattedMessage id="article.form.detectionFormTitleEdit" />
      </h1>
      <Row>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={20}
          xxl={20}
        >
          <Form
            form={form}
            onFinish={onFinish}
            layout="vertical"
            scrollToFirstError
            initialValues={
              offer && {
                ownerCommittee: offer?.ownerCommittee?.['@id'] ?? null,
                maddeDurumu: OFFER_STATUS[offer?.status] ?? null,
                title: offer?.title ?? null,
                articleGroup: offer?.articleGroup?.['@id'] ?? null,
                articleType: offer?.articleTypeOther !== null ? 'Diğer' : offer?.articleType?.['@id'],
                birinciEdisyondaYerAldi: offer?.oldTitle !== null ? 'Evet' : 'Hayır' ?? null,
                atifTam: offer?.oldTitle?.type ? (offer?.oldTitle?.type === 'TAM' ? 'Tam' : 'Atıf') : null,
                authors: offer?.authors?.map((item) => {
                  return {
                    id: item['@id'],
                    isCommitteeMember: item?.author?.user?.memberships?.length > 0 ? 'yes' : 'no',
                    authorSelection: item.author['@id'] ? 'list' : 'new',
                    author: item.author['@id'] ?? null,
                    reason: item.reason ?? null,
                  };
                }),
                oldArticleWorkType:
                  offer.oldArticleWorkType.includes(4) || offer.oldArticleWorkType.includes(5) ? offer.oldArticleWorkType[0] : offer.oldArticleWorkType,
                rewriteandUpdate: offer?.oldArticleWorkType?.some((item) => item !== 0) ? 'Güncelleme' : 'Yeniden Yazım',
                oldTitle: {
                  key: offer?.oldTitle?.id,
                  value: offer?.oldTitle?.id,
                  label: `${offer?.oldTitle?.title} (${offer?.oldTitle?.slug})`,
                },
                articleLength: offer?.articleLength,
                articleLengthCustom: offer?.articleLengthCustom,
                switch: Boolean(offer?.articleLengthDay),
                articleLengthDay: offer?.articleLengthDay,
                articleLengthReason: offer?.articleLengthReason,
                articleSupervisor: offer?.articleSupervisor?.['@id'] ?? null,
                articleSupervisorOther: offer?.articleSupervisorOther,
                yakinIfadeZikrediliyorMu: offer?.oldArticles?.length > 0 ? 'Evet' : 'Hayır',
                oldArticles: offer?.oldArticles?.map((item) => {
                  return {
                    key: item.id,
                    value: item.id,
                    label: `${item.title} (${item.slug})`,
                  };
                }),
                detectReason: offer.detectReason,
                articleTypeOther: offer.articleTypeOther,
                contentDescription: offer.contentDescription,
              }
            }
          >
            <ArticleDetection
              setFileList={setFileList}
              fileList={fileList}
              buttonLoading={buttonLoading}
            />
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default EditArticleForm;
