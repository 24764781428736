import React, { useMemo } from 'react';

import { Button, Card, Col, Collapse, Form, Popconfirm, Row, Table } from 'antd';
import { useDeleteArticleContentMediaMutation, useGetArticleContentQuery } from 'services';
import { CircularProgress } from 'components/CircularProgress';
import { DeleteOutlined, DownloadOutlined, EditOutlined, FileOutlined, PictureOutlined } from '@ant-design/icons';
import { downloadBlob, openNotification } from 'utils';
import { fileDownload } from 'fetcher';
import UploadedMediaModal from 'components/AddNewArticle/UploadedMediaModal';
import { useDispatch } from 'react-redux';
import { fetchError } from 'slices/commonSlice';
import { useIntl } from 'react-intl';

const mediaTypeOptions = {
  1: 'Fotoğraf',
  2: 'Video',
  3: 'Ses Dosyası',
  4: 'Harita',
  5: 'Grafik',
  6: 'İnfografik',
  7: 'Plan',
  8: 'Nota',
  9: 'Bayrak',
  10: 'Resim',
  11: 'Minyatür',
  12: 'Hat',
  13: 'Yazma Sayfası',
};

const handleBlobDownload = async (fileUrl, fileName) => {
  const { data } = await fileDownload(fileUrl);
  downloadBlob(data, fileName);
};

const contentFile = (fileName, fileUrl) => {
  return (
    <Card>
      <div className="gx-d-flex gx-align-items-center gx-justify-content-between">
        <div className="gx-d-flex gx-fit-text gx-align-items-center">
          <FileOutlined />
          <div className="gx-ml-2">{fileName}</div>
        </div>
        <Button
          type="link"
          icon={<DownloadOutlined className="gx-fs-xl" />}
          onClick={() => handleBlobDownload(fileUrl, fileName)}
        />
      </div>
    </Card>
  );
};

const ArticleOrderMedia = ({ offerID, modalProps, handleModalProps, handleDeleteFormMedia, handleEditStateTrigger, isContentSend = false }) => {
  const { data, isLoading } = useGetArticleContentQuery(
    {
      offerID,
    },
    {
      skip: !offerID,
    }
  );
  const [deleteArticleContentMedia] = useDeleteArticleContentMediaMutation();
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const form = Form.useFormInstance();

  const articleContent = useMemo(() => {
    return data?.contents.find((item) => item.type === 0);
  }, [data]);

  const authorDescription = useMemo(() => {
    return data?.authorDescription;
  }, [data]);

  const idCard = useMemo(() => {
    return data?.contents.find((item) => item.type === 1);
  }, [data]);
  const metadata = useMemo(() => {
    return data?.contents.filter((item) => item.type === 2);
  }, [data]);

  // bağlanmış olan bir maddeyi silmek için kullanılır
  const handleDelete = async (id) => {
    const { error } = await deleteArticleContentMedia(id);
    if (error) {
      return dispatch(
        fetchError(
          formatMessage(
            {
              id: 'article.form.fetchError',
            },
            { error: error.status }
          )
        )
      );
    }
    openNotification({
      message: formatMessage({
        id: 'appModule.actionSuccessfully',
      }),
    });
  };

  // yeni madde yollama ekranındaki medya teklifini düzenlemek için tablodaki icona basınca ilgili alanları form a set etmek için kullanılır
  const handleSetEdit = async (id) => {
    handleEditStateTrigger(false, null);
    const selectedItem = await metadata.filter((item) => item.id === id);
    handleEditStateTrigger(true, id);
    return form.setFieldsValue({
      metadata: await selectedItem?.map((metadata) => ({
        mediaCopyright: metadata.metadata.mediaCopyright,
        mediaAccept: metadata.metadata.mediaAccept,
        mediaType: metadata.metadata.mediaType,
        source: metadata.metadata.source,
        linkUrl: metadata.metadata.linkUrl,
        media: metadata.media.map((item) => ({ name: item.name, id: item.id, fileFullUrl: item.fileFullUrl, mediaID: `/api/media/${item.id}` })),
        description: metadata.metadata.description,
      })),
    });
  };

  // sadece yeni madde yollama ekranındaki tablodaki aksiyon butonları
  const sendStepActions = [
    {
      key: 'action',
      width: 100,
      render: (record) => (
        <div
          className="gx-d-flex gx-align-items-center"
          style={{
            gap: 8,
          }}
        >
          <EditOutlined
            onClick={() => {
              handleSetEdit(record.key);
            }}
            style={{
              cursor: 'pointer',
            }}
          />
          <Popconfirm
            placement="leftBottom"
            title="Silmek İstediğinizden Emin misiniz?"
            okText="Evet"
            onConfirm={() => handleDelete(record.key)}
          >
            <DeleteOutlined />
          </Popconfirm>
        </div>
      ),
    },
  ];

  const colums = [
    {
      title: 'Dosya türü',
      dataIndex: 'mediaType',
      key: 'key',
      width: 150,
      render: (text) => mediaTypeOptions[text],
    },
    {
      title: 'Medya Kaynağı',
      dataIndex: 'mediaSource',
      key: 'key',
    },
    {
      title: 'Medya Adı',
      dataIndex: 'media',
      key: 'key',
      render: (_, record) => (
        <>
          {record.media && record.media.length > 1 ? (
            <div
              className="gx-d-flex gx-align-items-center"
              style={{
                gap: 8,
                cursor: 'pointer',
              }}
              onClick={() => {
                handleModalProps(true, record.media);
              }}
            >
              <span>{record.media.length} medya</span>
              <PictureOutlined />
            </div>
          ) : (
            <div className="gx-d-flex gx-align-items-center">
              {record.media &&
                record.media.map((item) => {
                  return (
                    <>
                      <span className={'gx-mr-2'}>{item.name}</span>
                      {isContentSend ? (
                        <DownloadOutlined onClick={() => handleBlobDownload(item.fileFullUrl, item.name)} />
                      ) : (
                        <Popconfirm
                          placement="leftBottom"
                          title="Silmek İstediğinizden Emin misiniz?"
                          okText="Evet"
                          onConfirm={() => handleDeleteFormMedia(item.id)}
                        >
                          <DeleteOutlined />
                        </Popconfirm>
                      )}
                    </>
                  );
                })}
            </div>
          )}
        </>
      ),
    },
    {
      title: 'Medya Linki',
      dataIndex: 'mediaLink',
      key: 'key',
      render: (text) => {
        return text ? (
          <a
            target="_blank"
            rel="noreferrer noopener"
            href={text}
          >
            {text}
          </a>
        ) : (
          <>-</>
        );
      },
    },
  ];
  // tablodaki aksyon butonları sadece yeni madde yollarken kullanılacağı için bu kısımların kontrolü yapılıyor
  const tableColumns = [...colums, ...(!isContentSend ? sendStepActions : [])];
  const dataSource =
    metadata &&
    metadata?.map((metadata) => ({
      ...metadata,
      key: metadata.id,
      mediaType: metadata.metadata.mediaType,
      mediaSource: metadata.metadata.source,
      mediaLink: metadata.metadata.linkUrl,
      media: metadata.media.map((item) => ({ name: item.name, id: item.id, fileFullUrl: item.fileFullUrl })),
      mediaDescription: metadata.metadata.description,
    }));
  if (isLoading) return <CircularProgress />;

  return (
    <>
      <Card
        style={{
          display: !isContentSend && (!dataSource || dataSource.length === 0) ? 'none' : 'block',
        }}
      >
        {isContentSend && (
          <Row gutter={24}>
            {articleContent && (
              <Col
                sm={24}
                md={12}
              >
                <h4>Madde Dosyası</h4> {contentFile(articleContent.media[0].name, articleContent.media[0].fileFullUrl)}
              </Col>
            )}

            {idCard && (
              <Col
                sm={24}
                md={12}
              >
                <h4>Kimlik Kartı</h4>
                {contentFile(idCard.media[0].name, idCard.media[0].fileFullUrl)}
              </Col>
            )}
          </Row>
        )}

        {metadata && dataSource.length > 0 && (
          <>
            {isContentSend && <h4>Medya Teklifi</h4>}
            {isContentSend ? (
              <Table
                loading={isLoading}
                scroll={{ x: 1000 }}
                key={(record) => record.key}
                expandable={{
                  expandedRowRender: (record) => (
                    <>
                      <h4>Madde Açıklaması</h4>
                      <p>{record.mediaDescription}</p>
                    </>
                  ),
                  rowExpandable: (record) => record.mediaDescription,
                }}
                bordered
                columns={colums}
                dataSource={dataSource}
                pagination={dataSource.length > 10}
              />
            ) : (
              <Table
                loading={isLoading}
                scroll={{ x: 1000 }}
                key={(record) => record.key}
                expandable={{
                  expandedRowRender: (record) => (
                    <>
                      <h4>Madde Açıklaması</h4>
                      <p>{record.mediaDescription}</p>
                    </>
                  ),
                  rowExpandable: (record) => record.mediaDescription,
                }}
                bordered
                columns={tableColumns}
                dataSource={dataSource}
                pagination={dataSource.length > 10}
              />
            )}
          </>
        )}
        {isContentSend && authorDescription && (
          <Collapse>
            <Collapse.Panel
              header="Açıklamalar ve Notlar"
              key="1"
            >
              <p>{authorDescription}</p>
            </Collapse.Panel>
          </Collapse>
        )}
      </Card>
      {modalProps?.visible && (
        <UploadedMediaModal
          isContentSend={isContentSend}
          downloadBlob={handleBlobDownload}
          handleDeleteFormMedia={handleDeleteFormMedia}
          onClose={() => handleModalProps(false, null)}
          open={modalProps?.visible}
          data={modalProps?.data}
        />
      )}
    </>
  );
};

export default ArticleOrderMedia;
