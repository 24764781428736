import React, { Suspense } from 'react';

import { Route, Routes } from 'react-router-dom';
import { useGetUserInfo } from 'useSWR';
import { CircularProgress } from 'components';

import Offers from './offers';
import { allRoutes } from './allRoutes';
import { NotFound } from './404';
import { ErrorPage } from './500';

const App = () => {
  const { isAdmin, memberships, canOffer, isLoading, isResourceManager, isManager, isEncyclopedia, isAuthor } = useGetUserInfo();

  let routes = allRoutes.filter((route) => {
    if (isAdmin || isEncyclopedia) {
      return route;
    }

    return (
      route.situation.includes('all') ||
      (isManager && route.situation.includes('executive')) ||
      (isResourceManager && route.situation.includes('resourcesManager')) ||
      (canOffer && route.situation.includes('canOffer')) ||
      (isAuthor && route.situation.includes('author'))
    );
  });

  // kullanıcının heyetlerinin tüm routeları bu alanda eklenmektedir. Bir heyete ait tüm alt route lar eklenir.
  const memberShipsRoutes = [
    ...memberships?.map((item) => ({
      path: `/madde-teklifleri/${item.committee.id}`,
      component: <Offers />,
      situation: ['memberships'],
    })),
    ...memberships?.map((item) => ({
      path: `/madde-teklifleri/${item.committee.id}/teklif-edilen-maddeler`,
      component: <Offers />,
      situation: ['memberships'],
    })),
    ...memberships?.map((item) => ({
      path: `/madde-teklifleri/${item.committee.id}/sorumlu-olunan-maddeler`,
      component: <Offers />,
      situation: ['memberships'],
    })),
    ...memberships?.map((item) => ({
      path: `/madde-teklifleri/${item.committee.id}/gorus-istenen-maddeler`,
      component: <Offers />,
      situation: ['memberships'],
    })),
    ...memberships?.map((item) => ({
      path: `/madde-teklifleri/${item.committee.id}/heyetten-cikan-maddeler`,
      component: <Offers />,
      situation: ['memberships'],
    })),
    ...memberships?.map((item) => ({
      path: `/madde-teklifleri/${item.committee.id}/taslak-maddeler`,
      component: <Offers />,
      situation: ['memberships'],
    })),
  ];

  routes = [...routes, ...memberShipsRoutes];
  if (isLoading) return;

  return (
    <div className="gx-main-content-wrapper">
      <Suspense fallback={<CircularProgress />}>
        <Routes>
          {routes?.map((route, i) => (
            <Route
              key={route.path + i}
              path={route.path}
              element={route.component}
            />
          ))}
          <Route
            path="/hata"
            element={<ErrorPage />}
          />
          <Route
            path="/404"
            element={<NotFound />}
          />
        </Routes>
      </Suspense>
    </div>
  );
};

export default App;
