import React, { useState } from 'react';

import { CloseOutlined, DeleteOutlined, FileOutlined, SoundOutlined, VideoCameraOutlined } from '@ant-design/icons';
import { Button, Card, Col, Form, Input, message, Popconfirm, Radio, Row, Select, Tooltip, Upload } from 'antd';
import ArticleOrderMedia from 'components/ArticleOrder/ArticleOrderMedia';
import { useIntl } from 'react-intl';
import { checkFilesSize, chunkUploadMedia, openNotification } from 'utils';
import { useDeleteFormContentMediaMutation } from 'services';
import { useDispatch } from 'react-redux';
import { fetchError } from 'slices/commonSlice';

const { TextArea } = Input;

const mediaTypeOptions = [
  {
    label: 'Fotoğraf',
    value: 1,
  },
  {
    label: 'Video',
    value: 2,
  },
  {
    label: 'Ses Dosyası',
    value: 3,
  },
  {
    label: 'Harita',
    value: 4,
  },
  {
    label: 'Grafik',
    value: 5,
  },
  {
    label: 'İnfografik',
    value: 6,
  },
  {
    label: 'Plan',
    value: 7,
  },
  {
    label: 'Nota',
    value: 8,
  },
  {
    label: 'Bayrak',
    value: 9,
  },
  {
    label: 'Resim',
    value: 10,
  },
  {
    label: 'Minyatür',
    value: 11,
  },
  {
    label: 'Hat',
    value: 12,
  },
  {
    label: 'Yazma Sayfası',
    value: 13,
  },
];

const imageFileType = '.jpg, .jpeg, .png, .pdf, .bmp, .tiff, .webp, .svg';
const videoFileType = '.mp4, .avi, .mkv, .mov, .flv, .wmv, .webm';
const soundFileType = '.mp3, .wav, .flac, .aac, .ogg, .wma';

const AddMediaContent = ({ onSave, handleEditStateTrigger, handleUploadChange, offerID }) => {
  const { formatMessage } = useIntl();
  const form = Form.useFormInstance();
  const dispatch = useDispatch();
  const [deleteFormContentMedia] = useDeleteFormContentMediaMutation();

  const [modalProps, setModalProps] = useState({
    visible: false,
    data: null,
  });
  // bu fonksion yeni madde gönderme ekranındaki tabloda çoklu medyaya basıldığında açılan medya modalının durumunu kontrol için kullanılır
  const handleModalProps = (visible, data) => {
    setModalProps({
      visible,
      data,
    });
  };

  const metadata = Form.useWatch('metadata', form);

  const isMetadataExist = metadata && Array.isArray(metadata);

  // formdan seçili medya silmek için kullanılır zaten gelen istek de ilgili medya da silinip görüntülenecek bu sadsece anlık silme işleinin görünmesi için yapılan bir yöntem
  const handleSetNotDeletedMediaOnForm = (id) => {
    const updatedMetadata =
      isMetadataExist &&
      metadata.map((item) => {
        if (item.media) {
          return {
            ...item,
            media: item.media.filter((media) => media.id !== id),
          };
        }
        return item;
      });
    return form.setFieldsValue({
      metadata: updatedMetadata,
    });
  };

  // sadece bir maddeyle bağlı olmayan medya silme işlemi yapılır
  const handleDeleteFormMedia = async (id) => {
    const { error } = await deleteFormContentMedia(id);
    if (error) {
      return dispatch(
        fetchError(
          formatMessage(
            {
              id: 'article.form.fetchError',
            },
            { error: error.status }
          )
        )
      );
    }
    handleSetNotDeletedMediaOnForm(id);
    openNotification({
      message: formatMessage({
        id: 'appModule.actionSuccessfully',
      }),
    });

    setModalProps({
      visible: false,
      data: null,
    });
  };

  return (
    <>
      <h5 className="gx-pb-3 gx-pt-3">Medya Teklifi</h5>
      <Form.List name="metadata">
        {(fields, { add, remove }) => (
          <Form.Item shouldUpdate>
            <Card>
              <div
                style={{
                  width: '100%',
                  justifyContent: 'center',
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: 16,
                  gap: 8,
                }}
              >
                <VideoCameraOutlined
                  style={{
                    fontSize: 32,
                  }}
                />
                <SoundOutlined
                  style={{
                    fontSize: 32,
                  }}
                />
              </div>
              <div
                style={{
                  textAlign: 'center',
                }}
              >
                <h3>Medya Teklifi Başlık</h3>
                <p>
                  Yazdığınız maddede kullanılmasını istediğiniz, telifi size ait veya size ait olmayan dosyaları bu bölümde gönderebilirsiniz.{' '}
                  <strong>"Ekle"</strong> butonuna basarak daha fazla dosya ekleyebilirsiniz.
                </p>
              </div>
              <ArticleOrderMedia
                modalProps={modalProps}
                handleModalProps={handleModalProps}
                handleEditStateTrigger={handleEditStateTrigger}
                handleDeleteFormMedia={handleDeleteFormMedia}
                offerID={offerID}
              />
              {fields.length === 0 && (
                <Button
                  block
                  type="dashed"
                  onClick={() => add()}
                >
                  Medya Ekle
                </Button>
              )}
              {fields.map(({ key, name }) => (
                <Card
                  key={key}
                  style={{
                    marginTop: 16,
                  }}
                  bodyStyle={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 8,
                  }}
                >
                  <div>
                    <div className="gx-d-flex gx-justify-content-xl-end gx-w-100">
                      <CloseOutlined
                        onClick={() => {
                          handleEditStateTrigger(false, null);
                          form.resetFields(['metadata']);
                          remove(name);
                        }}
                      />
                    </div>
                    <Form.Item
                      label="Dosya Türü"
                      name={[name, 'mediaType']}
                      require
                      rules={[
                        {
                          required: true,
                          message: 'Lütfen dosya türünü seçiniz',
                        },
                      ]}
                    >
                      <Select
                        placeholder="Seçiniz"
                        options={mediaTypeOptions}
                      />
                    </Form.Item>

                    <Form.Item
                      className="gx-mt-2"
                      label="Telif Türü"
                      rules={[
                        {
                          required: true,
                          message: 'Lütfen medya kabul durumunu seçiniz',
                        },
                      ]}
                      name={[name, 'mediaAccept']}
                    >
                      <Radio.Group className="gx-w-100">
                        <Row
                          style={{
                            flexDirection: 'row',
                          }}
                          gutter={[24, 6]}
                        >
                          <Col
                            sm={24}
                            md={12}
                          >
                            <Radio
                              className="gx-p-2 gx-w-100"
                              style={{
                                border: '1px solid #f0f0f0',
                              }}
                              value={true}
                            >
                              Varolan bir Medyayı önermek / yüklemek istiyorum
                            </Radio>
                          </Col>
                          <Col
                            sm={24}
                            md={12}
                          >
                            <Radio
                              style={{
                                border: '1px solid #f0f0f0',
                                marginRight: 0,
                              }}
                              className="gx-p-2 gx-w-100"
                              value={false}
                            >
                              Bir medya üretilmesini teklif ediyorum
                            </Radio>
                          </Col>
                        </Row>
                      </Radio.Group>
                    </Form.Item>

                    <Form.Item
                      required
                      label="Açıklama"
                      name={[name, 'description']}
                      rules={[
                        {
                          required: true,
                          message: 'Lütfen açıklama giriniz',
                        },
                      ]}
                    >
                      <TextArea
                        rows={5}
                        placeholder="Açıklama giriniz"
                      />
                    </Form.Item>

                    <>
                      {isMetadataExist && metadata[name]?.mediaAccept !== null && metadata[name]?.mediaAccept === true && (
                        <Form.Item
                          className="gx-mt-2"
                          label="Kaynak"
                          s
                          rules={[
                            {
                              required: isMetadataExist && metadata[name].mediaAccept !== null && Boolean(metadata[name].mediaAccept),
                              message: 'Lütfen kaynak giriniz',
                            },
                          ]}
                          name={[name, 'source']}
                        >
                          <Input placeholder="Kaynak" />
                        </Form.Item>
                      )}
                      <Row
                        gutter={24}
                        align="middle"
                        style={{
                          flexDirection: 'row',
                          marginTop: 16,
                        }}
                      >
                        <Col
                          sm={24}
                          md={18}
                        >
                          <Form.Item
                            label="Link"
                            required={isMetadataExist && Boolean(metadata[name]?.mediaSource)}
                            name={[name, 'linkUrl']}
                          >
                            <Input placeholder="Link" />
                          </Form.Item>
                        </Col>

                        <Tooltip title={isMetadataExist && !metadata[name]?.mediaType ? 'Lütfen Önce dosya türü seçiniz' : ''}>
                          <Col
                            sm={24}
                            md={6}
                          >
                            <Form.Item label="Dosya Yükleme">
                              <Upload.Dragger
                                disabled={isMetadataExist && !metadata[name]?.mediaType}
                                showUploadList={false}
                                onChange={handleUploadChange}
                                accept={
                                  isMetadataExist && metadata[name]?.mediaType === 2
                                    ? videoFileType
                                    : metadata[name]?.mediaType === 3
                                    ? soundFileType
                                    : imageFileType
                                }
                                customRequest={async ({ file, onSuccess, onError }) => {
                                  try {
                                    if (file.size === 0) {
                                      return message.error('Dosya boş olamaz');
                                    }
                                    checkFilesSize([file]);
                                    const [res] = await chunkUploadMedia('articleContent', [file]);

                                    const updatedFile = {
                                      uid: file.uid,
                                      id: res.media.id,
                                      mediaID: `/api/media/${res.media.id}`,
                                      name: file.name,
                                      url: res.media.fileFullUrl,
                                      response: res.media,
                                    };
                                    form.setFieldsValue({
                                      metadata: {
                                        ...metadata,
                                        [name]: {
                                          ...metadata?.[name],
                                          media: [...((isMetadataExist && metadata[name]?.media) || []), updatedFile],
                                        },
                                      },
                                    });
                                    onSuccess('done');
                                  } catch (error) {
                                    onError('error');
                                  }
                                }}
                              >
                                {formatMessage({
                                  id: 'appModule.browse',
                                })}
                              </Upload.Dragger>
                            </Form.Item>
                          </Col>
                        </Tooltip>
                      </Row>
                      <Row
                        gutter={24}
                        align="middle"
                        style={{
                          flexWrap: 'wrap',
                          flexDirection: 'row',
                        }}
                      >
                        {isMetadataExist &&
                          metadata[name]?.media &&
                          metadata[name]?.media.length > 0 &&
                          metadata[name]?.media.map((file, index) => (
                            <Col
                              key={index}
                              sm={24}
                              md={12}
                              lg={8}
                              className="gx-d-flex gx-align-items-center gx-justify-content-between gx-p-2 gx-mr-2 gx-mb-4 gx-mt-4 gx-border "
                            >
                              <div className="gx-d-flex gx-align-items-center">
                                <FileOutlined
                                  style={{
                                    fontSize: 24,
                                  }}
                                />
                                <div className="gx-ml-2">{file.name}</div>
                              </div>
                              <Popconfirm
                                placement="leftBottom"
                                title="Silmek İstediğinizden Emin misiniz?"
                                okText="Evet"
                                onConfirm={() => handleDeleteFormMedia(file.id)}
                              >
                                <DeleteOutlined />
                              </Popconfirm>
                            </Col>
                          ))}
                      </Row>
                    </>
                  </div>

                  {isMetadataExist && metadata[name]?.mediaAccept !== null && metadata[name]?.mediaAccept === true && (
                    <Form.Item
                      label="Telif Durumu"
                      rules={[
                        {
                          required: true,
                          message: 'Lütfen telif durumunu seçiniz',
                        },
                      ]}
                      name={[name, 'mediaCopyright']}
                    >
                      <Radio.Group className="gx-w-100 gx-d-flex gx-align-items-center ">
                        <Radio
                          className="gx-w-50 gx-p-2"
                          style={{
                            border: '1px solid #f0f0f0',
                          }}
                          value={1}
                        >
                          Verdiğim medyanın telifi bana aittir ve kullanılmasına izin veriyorum
                        </Radio>
                        <Radio
                          style={{
                            border: '1px solid #f0f0f0',
                            marginRight: 0,
                          }}
                          className="gx-w-50 gx-p-2"
                          value={0}
                        >
                          Yüklediğim medyamın telifi bana ait değil
                        </Radio>
                      </Radio.Group>
                    </Form.Item>
                  )}
                  {fields.length - 1 === name && (
                    <Button
                      block
                      type="primary"
                      onClick={() =>
                        form
                          .validateFields([
                            ['metadata', name, 'mediaType'],
                            ['metadata', name, 'mediaAccept'],
                            ['metadata', name, 'description'],
                            ['metadata', name, 'source'],
                            ['metadata', name, 'mediaCopyright'],
                          ])
                          .then(() => {
                            onSave(form.getFieldsValue());
                            handleEditStateTrigger({
                              trigger: false,
                              mediaID: null,
                            });
                            remove(name);
                          })
                      }
                    >
                      {formatMessage({
                        id: 'appModule.save',
                      })}
                    </Button>
                  )}
                </Card>
              ))}
            </Card>
          </Form.Item>
        )}
      </Form.List>
    </>
  );
};

export default AddMediaContent;
