import React from 'react';

import { Button, message, Popconfirm, Upload } from 'antd';
import { DeleteOutlined, DownloadOutlined, InboxOutlined } from '@ant-design/icons';
import { useUpdateDisposalContactConfigMutation } from 'services';
import { chunkUploadMedia, downloadBlob } from 'utils';
import { fileDownload } from 'fetcher';

import UploadedFile from './AddNewArticle/UploadedFile';

const DisposedContract = ({ contractFileName, signedContractFileName, onCancel, disposalID }) => {
  const [updateDisposalContactConfig] = useUpdateDisposalContactConfigMutation();

  const handleBlobDownload = async (type, fileName) => {
    const { data } = await fileDownload(`disposal_contracts/${disposalID}/contract/${type}`);
    downloadBlob(data, fileName);
  };

  const handleDelete = async (text) => {
    await updateDisposalContactConfig({
      id: disposalID,
      body: {
        [text]: null,
      },
    });
  };

  const handleChange = (info) => {
    if (info.file.status === 'done') {
      message.destroy();
      message.success('Dosya yüklendi');
    } else if (info.file.status === 'error') {
      message.destroy();
      message.error('Dosya yüklenirken bir hata oluştu');
    } else if (info.file.status === 'uploading') {
      message.loading('Dosya yükleniyor', 0);
    }
  };

  const handleCustomUpload = async ({ file, onSuccess, onError }) => {
    try {
      const [res] = await chunkUploadMedia('disposalContract', [file]);
      const { error } = await updateDisposalContactConfig({
        id: disposalID,
        body: {
          signedContract: `/api/media/${res.media.id}`,
        },
      });
      if (error) {
        onError('error');
      }
      onSuccess('done');
    } catch (error) {
      onError('error');
    }
  };
  return (
    <div className="gx-mt-5 gx-pb-5">
      <UploadedFile
        content={contractFileName}
        extra={
          <div className="gx-d-flex gx-align-items-center">
            <Button
              className="gx-mb-0"
              onClick={() => handleBlobDownload('contract', contractFileName)}
              icon={<DownloadOutlined />}
            >
              İndir
            </Button>
            <Popconfirm
              title="Dosyayı silmek istediğinize emin misiniz?"
              onConfirm={() => handleDelete('contract')}
              okText="Evet"
              cancelText="Hayır"
            >
              <Button
                className="gx-mb-0"
                icon={<DeleteOutlined />}
              />
            </Popconfirm>
          </div>
        }
      />
      {signedContractFileName ? (
        <UploadedFile
          content={signedContractFileName}
          extra={
            <div className="gx-d-flex gx-align-items-center">
              <Button
                className="gx-mb-0"
                onClick={() => handleBlobDownload('signedContract', signedContractFileName)}
                icon={<DownloadOutlined />}
              >
                İndir
              </Button>
              <Popconfirm
                title="Dosyayı silmek istediğinize emin misiniz?"
                onConfirm={() => handleDelete('signedContract')}
                okText="Evet"
                cancelText="Hayır"
              >
                <Button
                  className="gx-mb-0"
                  icon={<DeleteOutlined />}
                />
              </Popconfirm>
            </div>
          }
        />
      ) : (
        <Upload.Dragger
          onChange={handleChange}
          accept=".pdf"
          customRequest={handleCustomUpload}
          showUploadList={false}
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">Islak İmzalı Tassarruf Sözleşmesi</p>
          <p className="ant-upload-hint">
            Yüklemek istediğiniz dosyayı buraya sürükleyip bırakarak ya da buraya tıkladıktan sonra depolama alanınızdan seçerek yükleyebilirsiniz
          </p>
        </Upload.Dragger>
      )}
      <Button
        block
        type="primary"
        onClick={onCancel}
        className="gx-mt-5"
      >
        Gönder
      </Button>
    </div>
  );
};

export default DisposedContract;
